import services from '../../services';

export const setOpenedEvent = (id) => {
    return dispatch => {
        services.events.get(id)
            .then((resp) => {
                dispatch({
                    type: 'SET_OPENED_EVENT',
                    payload: resp
                })
            })
    }
}

export const unsetOpenedEvent = () => {
    return dispatch => {
        dispatch({type: 'UNSET_OPENED_EVENT'})
    }
}
