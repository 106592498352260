import React from 'react';
import './discover-user-card.scss';
import {Box} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

const DiscoverUserCard = (props) => {
    const {user, onEdit, onDelete} = props

    return (
        <Box bgcolor="grid.main" className="discover-user-card">
            <img className="discover-card-avatar" src={`https://latencyapp.com${user.avatar ? user.avatar : "/images/defaultAvatar.png"}`} alt="latency" />
            <img className="discover-card-header" src={`https://latencyapp.com${user.header ? user.header : "/images/defaultHeader.png"}`} alt="latency" />
            <Box className="actions">
                <EditIcon className="icon-btn" onClick={() => onEdit(user)} />
                <DeleteIcon className="icon-btn" onClick={() => onDelete(user)} />
            </Box>
        </Box>
    )
}

export default DiscoverUserCard;
