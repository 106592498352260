import React, {useState, useEffect} from 'react';
import './tags.scss';
import { Grid } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import TagListing from '../../components/TagListing/TagListing';
import TagForm from '../../components/TagForm/TagForm';
import TagSearch from '../../components/TagSearch/TagSearch';
import Modal from '../../components/Modal/Modal';
import { setInterests, unsetInterests } from '../../store/interests/actions';

const NotFound = (props) => {
    const interests = useSelector(state => state.interests)
    const [all, setAll] = useState(interests)
    const [tag, setTag] = useState(null)
    const [modal, setModal] = useState(false)
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(setInterests())
        return () => {
            dispatch(unsetInterests())
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        setAll(interests)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [interests])

    const changeTag = (data) => {
        setTag(data)
        setModal(true)
    }

    const handleClose = () => {
        setTag(null)
        setModal(false)
    }

    const handleSearch = (search) => {
        if(search){
            let allData = [...all]
            let data = allData.filter(item => item.text.toLowerCase().includes(search.toLowerCase()))
            setAll(data)
        } else {
            setAll([...interests])
            setTag(null)
        }
    }

    return (
        <Grid container className="tags-page" spacing={2}>
            <Grid item xs={12}>
                <span className="box-title">tags listing</span>
            </Grid>
            <Grid item xs={12} container justify="center">
                <TagSearch tag={tag} openModal={() => setModal(true)} onSearch={handleSearch} />
            </Grid>
            <Grid item xs={12} container justify="center">
                <TagListing full={true} interests={all} onSelect={changeTag} />
            </Grid>
            <Modal
                state={modal}
                persist={false}
                max={"md"}
                dark={true}
                radius={true}
                onClose={handleClose}>

                <TagForm tag={tag} />
            </Modal>
        </Grid>
    )
}

export default NotFound;