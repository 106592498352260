const initialState = []

const dashboardReducer = (state = initialState, action) => {
    switch(action.type){
        case 'SET_DASHBOARD':
            return state = action.payload;
        case 'UNSET_DASHBOARD':
            return state = initialState
        default:
            return state
    }
}

export default dashboardReducer;