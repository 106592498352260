const initialState = {
    data: [],
}

const subscriptionsReducer = (state = initialState, action) => {
    switch(action.type){
        case 'SET_SUBSCRIPTIONS':
            return state = action.payload;
        case 'UNSET_SUBSCRIPTIONS':
            return state = initialState
        default:
            return state
    }
}

export default subscriptionsReducer;
