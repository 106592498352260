import React, { useEffect } from 'react';
import './dashboard.scss';
import {Grid} from '@material-ui/core';
import DashboardCounts from '../../components/DashboardCounts/DashboardCounts';
import { useDispatch } from 'react-redux';
import { setDashboard, unsetDashboard } from '../../store/dashboard/actions';

const Dashboard = (props) => {
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(setDashboard())
        return () => {
            dispatch(unsetDashboard())
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <Grid container className="dashboard">
            <Grid item xs={12}>
                <DashboardCounts />
            </Grid>
        </Grid>
    )
}

export default Dashboard;