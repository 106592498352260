import React, {useEffect, useState} from 'react';
import {Grid, Paper} from "@material-ui/core";
import {useSelector} from "react-redux";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputAdornment from "@material-ui/core/InputAdornment";
import './subscription.scss';
import Button from "@material-ui/core/Button";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

const SubscriptionInfoForm = ({itemId}) => {
    const {data: subs} = useSelector(state => state.subscriptions)
    const [item, setItem] = useState({});
    const [name, setName] = useState('');
    const [nameError, setNameError] = useState(false);
    const [price, setPrice] = useState(0);
    const [priceError, setPriceError] = useState(false);
    const [action, setAction] = useState(null);
    const [times, setTimes] = useState(1);
    const [frequency, setFrequency] = useState(null);

    useEffect(() => {
        let found = subs.find(e => e.id === itemId);
        if (found) {
            setItem(found);
            setName(found.name);
            setPrice(found.price);
        }
    }, [itemId, subs]);

    const handleChangeAction = (value) => {
        console.log(value)
    };

    const handleChangeFrequency = (value) => {
        console.log(value);
    };

    return (
        <Grid container className="top-container">
            <Grid item container direction="row" alignItems="flex-start" className="sub-info-form">
                <Grid item xs={12} className="header">
                    <span className="box-title">EDIT SUBSCRIPTION DETAILS</span>
                </Grid>
                <Grid item xs={12} className="first-row">
                    <FormControl variant="outlined">
                        <InputLabel htmlFor="outlined-adornment-amount">Name</InputLabel>
                        <OutlinedInput
                            id="outlined-adornment-name"
                            className={"title input-dark"}
                            value={name}
                            onChange={(e) => {
                                setName(e.target.value)
                                !e.target.value ? setNameError(true) : setNameError(false)
                            }}
                            labelWidth={60}
                        />
                    </FormControl>
                    <FormControl variant="outlined">
                        <InputLabel htmlFor="outlined-adornment-amount">Price</InputLabel>
                        <OutlinedInput
                            id="outlined-adornment-amount"
                            className={"title input-dark"}
                            value={price}
                            onChange={(e) => {
                                setPrice(e.target.value)
                                !e.target.value ? setPriceError(true) : setPriceError(false)
                            }}
                            startAdornment={<InputAdornment position="start">$</InputAdornment>}
                            labelWidth={60}
                        />
                    </FormControl>
                    <Button disableElevation color="primary">Save</Button>
                </Grid>
                <Grid item xs={12}>
                    <TableContainer component={Paper}>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Action</TableCell>
                                    <TableCell>Times</TableCell>
                                    <TableCell>Frequency</TableCell>
                                    <TableCell align="right">

                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell>
                                        <Select placeholder={"Action"} value={action} onChange={handleChangeAction}>
                                            <MenuItem value={"change_profile_slug"}>Change Profile Slug</MenuItem>
                                            <MenuItem value={"spotlight_event_poster"}>Spotlight Event Poster</MenuItem>
                                            <MenuItem value={"spotlight_event_color"}>Spotlight Event Color</MenuItem>
                                            <MenuItem value={"gallery_event_poster"}>Gallery Event Poster</MenuItem>
                                        </Select>
                                    </TableCell>
                                    <TableCell>
                                        <TextField id="standard-basic" value={times} onChange={(e) => setTimes(e.target.value)} placeholder={"Times"} />
                                    </TableCell>
                                    <TableCell>
                                        <Select placeholder={"Frequency"} value={frequency} onChange={handleChangeFrequency}>
                                            <MenuItem value={"hourly"}>Hourly</MenuItem>
                                            <MenuItem value={"daily"}>Daily</MenuItem>
                                            <MenuItem value={"weekly"}>Weekly</MenuItem>
                                            <MenuItem value={"monthly"}>Monthly</MenuItem>
                                            <MenuItem value={"unlimited"}>Unlimited</MenuItem>
                                        </Select>
                                    </TableCell>
                                    <TableCell align="right">
                                        <Button>Create</Button>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default SubscriptionInfoForm;
