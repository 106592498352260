import React from 'react';
import './modal.scss';
import { Dialog } from '@material-ui/core';

const GenericModal = (props) => {

    return (
        <Dialog 
            open={props.state}
            onClose={props.onClose}
            classes={{paper: 'modal ' + (props.radius ? ' radius ' : ' ') + (' dark') + ' ' + props.classes}}
            maxWidth={props.max}
            disableBackdropClick={props.persist}>
                
            {props.children}
        </Dialog>
    )
}

export default GenericModal;
