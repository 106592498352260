import React, {useState} from 'react';
import './login-form.scss';
import {Box, TextField, Button} from '@material-ui/core';

const LoginForm = (props) => {
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [emailError, setEmailError] = useState(false)
    const [passwordError, setPasswordError] = useState(false)

    const onEmailChange = (e) => {
        setEmail(e.target.value)
        !e.target.value ? setEmailError(true) : setEmailError(false)
        props.onChange()
    }
    const onPasswordChange = (e) => {
        setPassword(e.target.value)
        !e.target.value ? setPasswordError(true) : setPasswordError(false)
        props.onChange()
    }

    const handleSubmit = () => {
        if(email){
            if(password){
                props.onSubmit({email: email, password: password})
            } else {
                setPasswordError(true)
            }
        } else {
            setEmailError(true)
        }
    }

    const handleKeyDown = (event) => {
        if(event.key === 'Enter'){
            handleSubmit()
        }
    }

    return (
        <Box className="login-form" minWidth="300px" maxWidth="300px" display="flex" flexDirection="column" justify="center" alignItems="center">
            <TextField
                className="email input-dark"
                error={emailError}
                type="email"
                label={"Enter your email"}
                value={email}
                variant="outlined"
                onChange={onEmailChange}
                onKeyDown={handleKeyDown} />
            
            <TextField 
                className="password input-dark"
                error={passwordError}
                type="password"
                label={"Enter you password"}
                value={password}
                variant="outlined"
                onChange={onPasswordChange}
                onKeyDown={handleKeyDown} />


            {props.error && <span className="error"> Email or Password incorrect </span>}
            
            <Button className="submit-btn" color="primary" onClick={handleSubmit}>Login</Button>
        </Box>
    )
}

export default LoginForm;
