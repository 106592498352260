import React, { useState } from 'react';
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import {useSelector} from 'react-redux';
import { Box } from '@material-ui/core';
import services from '../../../services';

const ParticipantsList = (props) => {
    const participantsList = useSelector(state => state.participantsList)
    const darkTheme = useSelector(state => state.darkTheme)
    const [values, setValues] = useState(props.initialData && props.initialData.length ? props.initialData : [])
    const [list, setList] = useState(participantsList)
    const [loading, setLoading] = useState(false)

    const prepareData = (data) => {
        let newData = []
        let obj = {}
        let groups = data.groups
        let users = data.users
        for(let a=0; a<groups.length; a++){
            if(!groups[a].add){
                obj.type = "group"
                obj.data = groups[a]
                obj.nickname = groups[a].name
                newData.push(obj)
                obj = {}
            }
        }

        for(let a=0; a<users.length; a++){
            newData.push(users[a])
        }
        setList(newData)
    }

    const handleChange = (event, data) => {
        let pp = []
        let group = {}
        for(let a=0; a<data.length; a++){
            if(data[a].type === "group"){
                group = data[a]
                data.splice(a, 1)
            } else {
                let found = pp.find(item => item.id ===data[a].id)
                if(!found){
                    pp.push(data[a])
                }
            }
        }
        if(group.data){
            for(let b=0; b<group.data.members.length; b++){
                let found = pp.find(item => item.id ===group.data.members[b].id)
                if(found){
                    pp = pp.filter(item => item.id !== found.id)
                } else {
                    let found = participantsList.find(item => item.id ===group.data.members[b].id)
                    pp.push(found)
                }
            }
        }

        setValues(pp)
        props.handleChange(pp)
    }

    const handleSearch = (event) => {
        if(event.target.value && event.target.value.length > 2){
            setLoading(true)
            services.search.find({
                query: {
                    text: event.target.value,
                    participants: true
                }
            })
            .then((resp) => {
                prepareData(resp)
                setLoading(false)
            })
            .catch((error) => {
                console.log(error)
            })
        } else {
            setList(participantsList)
        }
    }

    return (
        <Autocomplete
            renderOption={(option, { selected }) => (
                <Box className="part-list-option" display="flex" flexDirection="row" justifyContent="flex-start" alignItems="center">
                    {option.type === "group" && <span className={"color-box " + (option.data.color)}></span>}
                    <span className="text">{option.nickname}</span>
                </Box>
            )}
            renderInput={params => (
                <TextField
                    {...params}
                    variant="outlined"
                    onChange={handleSearch}
                    placeholder={!props.private ? "Add Participants" : "Add Private Participants"}
                    fullWidth
                />
            )}
            className={"tag-select " + (darkTheme ? "tag-select-dark" : "tag-select-light")}
            multiple
            loading={loading}
            classes={{tag: "chip", popper: props.quick ? "quick-tag-select-dropdown" : "full-tag-select-dropdown", endAdornment: "clear-icon", popupIndicator: "down-btn", loading: "static", noOptions: "static"}}
            options={list}
            getOptionLabel={option => option.nickname}
            value={values}
            onChange={handleChange}
        />
    )
}


export default ParticipantsList;