import feathers from '@feathersjs/feathers';
import socketio from '@feathersjs/socketio-client';
import io from 'socket.io-client';
import auth from '@feathersjs/authentication-client';

const socket = io(process.env.REACT_APP_API_URL, {
    transports: ['websocket'],
    upgrade: false
});
const socketOptions = { timeout: Number(process.env.REACT_APP_REQUEST_TIMEOUT) }
const client = feathers().configure(socketio(socket, socketOptions)).configure(auth({ storage: window.localStorage }))

export default client;
