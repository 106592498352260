import services from '../../services/';

export const setDiscoverUsers = (data) => {
    return dispatch => {
        services.discover.find({query: {$limit: 100}})
            .then((resp) => {
                dispatch({
                    type: 'SET_DISCOVER_USERS',
                    payload: resp.data
                })
            })
    }
}

export const unsetDiscoverUsers = () => {
    return dispatch => {
        dispatch({type: 'UNSET_DISCOVER_USERS'})
    }
}
