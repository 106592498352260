import React, { useState } from 'react';
import './tag-search.scss';
import { Box, TextField, Button } from '@material-ui/core';

const TagForm = (props) => {
    const [name, setName] = useState("")

    const handleChange = (e) => {
        setName(e.target.value)
        props.onSearch(e.target.value)
    }

    return (
        <Box mt={3} minWidth="50%" maxWidth="550px" display="flex" flexDirection="row" justifyContent="center" className="tag-search">
            <TextField
                className={"input-dark"}
                type="text"
                placeholder="Search tags"
                value={name}
                variant="outlined"
                onChange={handleChange}
            />
            <Button className="submit-btn" onClick={props.openModal}>Create Tag</Button>
        </Box>
    )
}

export default TagForm;