import React, { useState, useEffect, Fragment } from 'react';
import './promote-discover.scss';
import { Grid, Button, Box } from '@material-ui/core';
import Modal from '../../components/Modal/Modal';
import DiscoverForm from '../../components/Forms/DiscoverForm/DiscoverForm';
import { useSelector, useDispatch } from 'react-redux';
import DiscoverUserCard from '../../components/DiscoverUserCard/DiscoverUserCard';
import services from '../../services';
import ConfirmationBox from '../../components/ConfirmationBox/ConfirmationBox';
import { setDiscoverUsers, unsetDiscoverUsers } from '../../store/discoverUsers/actions';

const PromoteDiscover = (props) => {
    const [open, setOpen] = useState(false)
    const discoverUsers = useSelector(state => state.discoverUsers)
    const [selectedEditUser, setEditUser] = useState({})
    const [selectedDeleteUser, setDeleteUser] = useState({})
    const [confirmDialog, setConfirmDialog] = useState(false)
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(setDiscoverUsers())
        return () => {
            dispatch(unsetDiscoverUsers())
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleEdit = (data) => {
        setEditUser(data)
        setOpen(true)
    }

    const handleDelete = (data) => {
        setDeleteUser(data)
        setConfirmDialog(true)
    }

    const deleteDiscoverUser = () => {
        setConfirmDialog(false)
        services.discover.remove(selectedDeleteUser.id)
            .then((resp) => {
                dispatch(setDiscoverUsers())
            })
            .catch((error) => {
                console.log(error)
            })
    }

    const handleClose = () => {
        setOpen(false)
        setEditUser({})
        setDeleteUser({})
    }

    return (
        <Grid container className="promote-discover-page">
            <Grid item xs={5} container justify="flex-start">
                <Button className="submit-btn" onClick={() => setOpen(true)}>promote</Button>
            </Grid>
            <Grid item xs={7} container justify="flex-start">
                <span className="box-title">promote discover users</span>
            </Grid>
            <Grid item xs={12}>
                <Box mt={5} minWidth="100%" display="flex" flexDirection="row" justifyContent="flex-start" flexWrap="wrap">
                    {discoverUsers && discoverUsers.map((item, index) =>
                        <DiscoverUserCard onEdit={handleEdit} onDelete={handleDelete} key={index} user={item} />
                    )}
                </Box>
            </Grid>
            <Modal
                state={open}
                persist={false}
                max={"md"}
                dark={true}
                radius={true}
                onClose={handleClose}>

                <DiscoverForm user={selectedEditUser} onClose={handleClose} />
            </Modal>
            <ConfirmationBox
                state={confirmDialog}
                persist={false}
                max={"sm"}
                dark={true}
                radius={false}
                onAgree={() => deleteDiscoverUser()}
                onDisagree={() => setConfirmDialog(false)}
                onClose={() => setConfirmDialog(false)}>

                <Fragment>Are you sure, you want to delete this user?</Fragment>
            </ConfirmationBox>
        </Grid>
    )
}

export default PromoteDiscover;
