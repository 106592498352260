import services from '../../services/';

export const setEvents = (page) => {
    return dispatch => {
        const limit = 10
        const skip = page * limit
        const query = {query: {$limit: limit, $skip: skip, $sort: {createdAt: -1}, admin: true}}
        services.events.find(query)
            .then((resp) => {
                resp.page = page
                dispatch({
                    type: 'SET_EVENTS',
                    payload: resp
                })
            })
            .catch((error) => {
                console.log(error)
            })
    }
}

export const unsetEvents = () => {
    return dispatch => {
        dispatch({type: 'UNSET_EVENTS'})
    }
}
