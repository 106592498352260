import React from 'react';
import './explore-user-card.scss';
import {Box} from '@material-ui/core';

const ExploreUserCard = (props) => {
    const {profile} = props

    return (
        <Box className={"explore-user-card"} display="flex" flexDirection="row" bgcolor="background.default">
            <img src={process.env.REACT_APP_FRONTEND_URL + profile.avatar} className="avatar" alt="latency" />
            <Box className="user-card-content" display="flex" flexDirection="column" justifyContent="center" alignItems="flex-start">
               <span className="name"> {profile.nickname} </span>
            </Box>
        </Box>
    )
}

export default ExploreUserCard;
