import React, {Fragment} from 'react';
import './logo.scss';
import logoWhite from '../../assets/images/logo-white.svg';
import logoComplex from '../../assets/images/logo-complex.svg';


const Logo = (props) => {
    return (
        <Fragment>
            <img src={props.variant === 'white' ? logoWhite : logoComplex} className={props.className} alt="Latency" />
        </Fragment>
    )
}

export default Logo;