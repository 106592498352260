import React, {useEffect, useState} from 'react';
import './explore-listing.scss';
import {Box, Button} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import services from '../../../services';
import Modal from '../../Modal/Modal';
import ExploreEventForm from './ExploreEventForm/ExploreEventForm';
import ExploreUserForm from './ExploreUserForm/ExploreUserForm';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import ExploreEventCard from '../../ExploreEventCard/ExploreEventCard';
import ExploreUserCard from '../../ExploreUserCard/ExploreUserCard';
import { useDispatch } from 'react-redux';
import { setExplore } from '../../../store/explore/actions';
import CircleAdd from "../../../assets/icons/actions/add_event_circle.svg";
import ImageCropper from "../../ImageCropper/ImageCropper";
import ExploreEditForm from "./ExploreEditForm/ExploreEditForm";

const ExploreUserEventForm = (props) => {
    const {data} = props;
    const [userModal, showUserModal] = useState(false);
    const [eventModal, showEventModal] = useState(false);
    const [editModal, showEditModal] = useState(false);
    const [editItem, setEditItem] = useState();
    const [cropModal, showCropModal] = useState(false);
    const [croppedPoster, setCroppedPoster] = useState(null);
    const [cropHeadingModal, showCropHeadingModal] = useState(false);
    const [croppedHeading, setCroppedHeading] = useState(null);
    const [marketingTagline, setMarketingTagline] = useState(data.marketing_tagline ? data.marketing_tagline : "");
    const dispatch = useDispatch();

    useEffect(() => {
        setMarketingTagline(data.marketing_tagline ? data.marketing_tagline : "");
        return () => {
            showUserModal(false);
            showEventModal(false);
            showEditModal(false);
            setEditItem(null);
            setCroppedPoster(null);
            showCropModal(false);
            showCropHeadingModal(false);
            setCroppedHeading(null);
            setMarketingTagline("");
        }
    }, [data]);

    const posterChange = (e) => {
        showCropModal(true);
        let file = e.target.files[0];
        var reader = new FileReader();
        reader.onloadend = () => {
            setCroppedPoster(reader.result)
        };
        reader.readAsDataURL(file);
    };

    const headingChange = (e) => {
        showCropHeadingModal(true);
        let file = e.target.files[0];
        var reader = new FileReader();
        reader.onloadend = () => {
            setCroppedHeading(reader.result)
        };
        reader.readAsDataURL(file);
    };

    const deleteEvent = (item) => {
        let uu = [...data.users].map(u => u.id)
        let ee = [...data.events]
        ee = ee.filter(data => data.id !== item.id)
        ee = ee.map(e => e.id)
        services.explore.update(data.id, {users: uu, events: ee, poster: data.poster})
            .then((resp) => {
                dispatch(setExplore())
            })
            .catch((error) => {
                console.log(error)
            })
    }

    const deleteUser = (item) => {
        let uu = [...data.users]
        uu = uu.filter(data => data.id !== item.id)
        uu = uu.map(u => u.id)
        let ee = [...data.events].map(e => e.id)
        services.explore.update(data.id, {users: uu, events: ee, poster: data.poster})
            .then((resp) => {
                dispatch(setExplore())
            })
            .catch((error) => {
                console.log(error)
            })
    }

    const editPromotion = (item) => {
        setEditItem(item);
        showEditModal(true);
    };

    const savePoster = () => {
        services.explore.patch(data.id, {poster: croppedPoster})
            .then((resp) => {
                dispatch(setExplore())
            })
            .catch((error) => {
                console.log(error)
            })
    };

    const saveHeading = () => {
        let submit = {
            marketing_tagline: marketingTagline
        };
        if(croppedHeading && croppedHeading.length > 0) {
            submit.heading = croppedHeading;
        }
        services.explore.patch(data.id, submit)
            .then((resp) => {
                dispatch(setExplore())
            })
            .catch((error) => {
                console.log(error)
            })
    };

    return (
        <Box className="explore-user-event-form" display="flex" flexDirection="column">

            <Box display="flex" flexDirection="row" justifyContent="space-around" alignItems="stretch">

                <Box className="poster" m={5} display="flex" flexDirection="column" justifyContent="flex-start" alignItems="stretch">
                    <Box className="head" display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
                        <span className="subtitle">Poster</span>
                        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                            <label id="add-event-image-label" className="cursor-pointer" htmlFor="add-event-image"><img src={CircleAdd} alt="latency" /></label>
                            <input type="file" id="add-event-image" accept="image/gif, image/jpeg, image/png" onChange={posterChange} />
                        </Box>
                    </Box>
                    <img className="poster-display" src={croppedPoster ? croppedPoster : (data.poster && data.poster.length > 0) ? process.env.REACT_APP_FRONTEND_URL + data.poster : ""} alt=""/>
                    {croppedPoster && croppedPoster.length > 0 && (<Button className="save-poster-btn" onClick={savePoster}>Update new Poster</Button>)}
                </Box>

                <Box className="explore-header" m={5}>
                    <Box className="head" display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
                        <span className="subtitle">Cabecera promoción</span>
                        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                            <label id="add-heading-explore-label" className="cursor-pointer" htmlFor="add-heading-explore"><img src={CircleAdd} alt="latency" /></label>
                            <input type="file" id="add-heading-explore" accept="image/gif, image/jpeg, image/png" onChange={headingChange} />
                        </Box>
                    </Box>
                    <Box className="explore-header-img-wrap">
                        <div className="header-marketing">
                            <textarea name="marketing" value={marketingTagline} onChange={(e) => setMarketingTagline(e.target.value)} placeholder="Marketing tagline" />
                        </div>
                        <img className="header-display" src={croppedHeading ? croppedHeading : (data.heading && data.heading.length > 0) ? process.env.REACT_APP_FRONTEND_URL + data.heading : ""} />
                        {(croppedHeading && croppedHeading.length > 0 || data.marketing_tagline !== marketingTagline) && (<Button className="save-poster-btn" onClick={saveHeading}>Update new header</Button>)}
                        <div className="overlay"></div>
                    </Box>
                </Box>

            </Box>
            <Box display="flex" flexDirection="row">
                <Box className="wrap-explore-content" mt={5} display="flex" flexDirection="row" justifyContent="space-between" alignItems="stretch">
                    <Box className="events" m={5}>
                        <Box className="head" display="flex" flexDirection="row" justifyContent="space-between" alignItems="flex-start">
                            <span className="subtitle">added events</span>
                            <AddIcon className="plus-icon" onClick={() => showEventModal(true)} />
                        </Box>
                        <Box className="content hide-scroll">
                            {data.events && data.events.map(item =>
                                <Box mt={2} key={item.id} display="flex" flexDirection="row" justifyContent="space-around" alignItems="center">
                                    <ExploreEventCard event={item} />
                                    <Box display="flex" flexDirection="column" justifyContent="space-between" alignItems="center">
                                        <EditIcon onClick={() => editPromotion(item)} className="del-icon" />
                                        <DeleteIcon onClick={() => deleteEvent(item)} className="del-icon" />
                                    </Box>
                                </Box>
                            )}
                        </Box>
                    </Box>
                    <Box className="users hide-scroll"  m={5}>
                        <Box className="head" display="flex" flexDirection="row" justifyContent="space-between" alignItems="flex-start">
                            <span className="subtitle">added users</span>
                            <AddIcon className="plus-icon" onClick={() => showUserModal(true)} />
                        </Box>
                        <Box className="content hide-scroll">
                            {data.users && data.users.map(item =>
                                <Box mt={2} key={item.id} display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
                                    <ExploreUserCard profile={item} />
                                    <DeleteIcon onClick={() => deleteUser(item)} className="del-icon" />
                                </Box>
                            )}
                        </Box>
                    </Box>
                </Box>
            </Box>

            <Modal
                state={cropModal}
                persist={false}
                max={"lg"}
                fullScreen={false}
                onClose={() => showCropModal(false)} >

                <ImageCropper
                    cropper={{width: 300, height: 350}}
                    poster={croppedPoster}
                    className="add-event"
                    onCrop={(img) => {
                        setCroppedPoster(img);
                    }}
                    onClose={() => showCropModal(false)} />
            </Modal>
            <Modal
                state={cropHeadingModal}
                persist={false}
                max={"lg"}
                fullScreen={false}
                onClose={() => showCropHeadingModal(false)} >

                <ImageCropper
                    cropper={{width: 1544, height: 350}}
                    poster={croppedHeading}
                    className="add-event"
                    onCrop={(img) => {
                        setCroppedHeading(img);
                    }}
                    onClose={() => showCropHeadingModal(false)} />
            </Modal>
            <Modal
                state={userModal}
                persist={false}
                max={"lg"}
                radius={true}
                fullScreen={false}
                onClose={() => showUserModal(false)}>

                <ExploreUserForm data={data} onClose={() => showUserModal(false)} />
            </Modal>
            <Modal
                state={eventModal}
                persist={false}
                max={"lg"}
                radius={true}
                fullScreen={false}
                onClose={() => showEventModal(false)}>

                <ExploreEventForm data={data} onClose={() => showEventModal(false)} />
            </Modal>
            <Modal
                state={editModal}
                persist={false}
                max={"lg"}
                radius={true}
                fullScreen={false}
                onClose={() => showEditModal(false)}>

                <ExploreEditForm data={data} edit={editItem} onClose={() => showEditModal(false)} />
            </Modal>
        </Box>
    )
}

export default ExploreUserEventForm;
