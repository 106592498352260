import React, { useState, useEffect } from 'react';
import {useSelector} from 'react-redux';
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from '@material-ui/core/TextField';
import services from '../../../services';

const CategoriesList = (props) => {
    const userCategories = useSelector(state => state.userCategories)
    const darkTheme = useSelector(state => state.darkTheme)
    const [list, setList] = useState(userCategories)
    const [loading, setLoading] = useState(false)
    const [values, setValues] = useState([])

    useEffect(() => {
        if(props.initialData && props.initialData.length){
            let cc = []
            for(let a=0; a<props.initialData.length; a++){
                cc.push(props.initialData[a].name)
            }
            setValues(cc)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleChange = (event, data) => {
        if(data.length > 0){
            let text = data[data.length-1]
            let found = list.find(item =>  item.toLowerCase().includes(text.toLowerCase()))
            if(found){
                let index = data.findIndex(item => item === text)
                data[index] = found
            }
        }
        setValues(data)
        props.handleChange(data);
    }

    const handleSpace = (event) => {
        if(event.keyCode === 32){
            let text = event.target.value
            let data = values.length > 0 ? [...values] : []
            let found = list.find(item =>  item.toLowerCase().includes(text.toLowerCase()))
            if(found){
                data.push(found)
            } else {
                data.push(text)
            }
            props.handleChange(data)
            setValues(data)
        }
    }

    const handleSearch = (event) => {
        if(event.target.value && event.target.value.length > 2){
            setLoading(true)
            services.search.find({
                query: {
                    text: event.target.value,
                    categories: 1
                }
            })
            .then((resp) => {
                let data = resp.map(item => item.name)
                setList(data)
            })
        } else {
            setList(userCategories)
        }
    }

    return (
        <Autocomplete
            freeSolo
            renderInput={params => (
                <TextField
                    {...params}
                    variant="outlined"
                    placeholder="Add Categories"
                    onKeyDown={handleSpace}
                    onChange={handleSearch}
                    fullWidth
                />
            )}
            className={"tag-select"}
            multiple
            loading={loading}
            autoSelect={true}
            autoComplete={true}
            value={values}
            classes={{tag: "chip", loading: "loading", noOptions: "static"}}
            options={list}
            onChange={handleChange}
        />
    )
}


export default CategoriesList;
