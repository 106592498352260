import React, { useEffect, useState } from 'react';
import './events.scss';
import {Grid} from '@material-ui/core';
import EventListing from '../../components/EventListing/EventListing';
import { useDispatch, useSelector } from 'react-redux';
import { unsetEvents, setEvents } from '../../store/events/actions';
import SearchBar from '../../components/SearchBar/SearchBar';

const EventsPage = (props) => {
    const events = useSelector(state => state.events)
    const [list, setList] = useState(events)
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(setEvents(0))
        return () => {
            dispatch(unsetEvents())
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        setList(events)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [events])

    const handleSearch = (data) => {
        setList(data)
    }

    const handleReset = () => {
        setList(events)
    }

    return (
        <Grid container className="events-page">
            <Grid item xs={12}>
                <span className="box-title"> events listing</span>
            </Grid>
            <Grid item xs={12} container justify="center">
                <SearchBar onSearch={handleSearch} onReset={handleReset} type="event" placeholder="Search events"  />
            </Grid>
            <Grid item container direction="row" justify="center" xs={12}>
                <EventListing events={list} />
            </Grid>
        </Grid>
    )
}

export default EventsPage;