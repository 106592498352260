import React, {useEffect, useState} from 'react';
import {Box, Button} from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import TextField from "@material-ui/core/TextField";
import services from "../../../../services";
import {setExplore} from "../../../../store/explore/actions";
import {useDispatch} from "react-redux";
import './explore-edit-form.scss';

const ExploreEditForm = (props) => {
    const {onClose, data, edit} = props;
    const dispatch = useDispatch();
    const [highlight, setHighlight] = useState(edit.explore_event.highlight);
    const [weight, setWeight] = useState(edit.explore_event.weight);

    const submit = () => {
        services.exploreEvent.patch(data.id, {highlight, weight}, {
            query: {
                eventId: edit.id
            }
        })
            .then((resp) => {
                dispatch(setExplore())
                props.onClose()
            })
            .catch((error) => {
                console.log(error)
            })
    };

    return (
        <Box className="explore-edit-form">
            <Box mb={5} minWidth="100%" maxWidth="100%">
                <span className="box-title">Promote</span>
            </Box>
            <Box mb={2} minWidth="100%" maxWidth="100%" display="flex" flexDirection="column" >
                <div className="form">
                    <FormControlLabel
                        control={<Switch checked={highlight} onChange={() => setHighlight(!highlight)} name="checkedA" />}
                        label="Promote"
                    />
                    <TextField
                        id="standard-number"
                        label="Weight"
                        type="number"
                        value={weight}
                        onChange={(e) => setWeight(e.target.value)}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </div>
            </Box>
            <Box mt={5} minWidth="100%" maxWidth="100%" display="flex" flexDirection="row" justifyContent="center">
                <Button className="cancel-btn" onClick={onClose}>Cancel</Button>
                <Button className="submit-btn" onClick={submit}>Save</Button>
            </Box>
        </Box>
    );
};

export default ExploreEditForm;
