export const openUserInfoModal = () => {
    return dispatch => {
        dispatch({type: 'OPEN_USER_INFO_MODAL'})
    }
}

export const closeUserInfoModal = () => {
    return dispatch => {
        dispatch({type: 'CLOSE_USER_INFO_MODAL'})
    }
}
