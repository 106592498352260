import React, { useState, useEffect } from 'react';
import './users.scss';
import {Grid} from '@material-ui/core';
import UserListing from '../../components/UserListing/UserListing';
import { useDispatch, useSelector } from 'react-redux';
import { setUsers, unsetUsers } from '../../store/users/actions';
import SearchBar from '../../components/SearchBar/SearchBar';

const UsersPage = (props) => {
    const users = useSelector(state => state.users)
    const [list, setList] = useState(users)
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(setUsers(0))
        return () => {
            dispatch(unsetUsers())
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        setList(users)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [users])

    const handleSearch = (data) => {
        setList(data)
    }

    const handleReset = () => {
        setList(users)
    }

    return (
        <Grid container className="users-page">
            <Grid item xs={12}>
                <span className="box-title"> users listing</span>
            </Grid>
            <Grid item xs={12} container justify="center" alignItems="center">
                <SearchBar onSearch={handleSearch} onReset={handleReset} type="user" placeholder="Search users" />
            </Grid>
            <Grid item container direction="row" justify="center" xs={12}>
                <UserListing users={list} />
            </Grid>
        </Grid>
    )
}

export default UsersPage;