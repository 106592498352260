import React, { useState } from 'react';
import './tag-form.scss';
import { Grid, TextField, Button } from '@material-ui/core';
import {useDispatch} from 'react-redux';
import services from '../../services';
import { showSnackbar } from '../../store/snackbar/actions';
import {setInterests} from '../../store/interests/actions';

const TagForm = (props) => {
    const {tag} = props
    const [text, setText] = useState(tag ? tag.text : "")
    const [spanishData, setSpanish] = useState("")
    const [englishData, setEnglish] = useState("")
    const [spanishError, setSpanishError] = useState(false)
    const [englishError, setEnglishError] = useState(false)
    const [textError, setTextError] = useState(false)
    const dispatch = useDispatch()

    const handleSubmit = (name) => {
        if(text){
            if(spanishData){
                if(englishData){
                    let data = prepareData()
                    services.interests.create(data)
                        .then((resp) => {
                            dispatch(setInterests())
                            dispatch(showSnackbar({message: `tag (${name}) added successfully`, type: "success"}))
                        })
                        .catch((error) => {
                            console.log(error)
                            dispatch(showSnackbar({message: `Error!! Somthing went wrong`, type: "error"}))
                        })
                } else {
                    setEnglishError(true)
                }
            } else {
                setSpanishError(true)
            }
        } else {
            setTextError(true)
        }
    }

    const handleEdit = (name) => {
        if(tag){
            if(text){
                if(spanishData){
                    if(englishData){
                        let data = prepareData()
                        services.interests.patch(tag.id, data, null)
                            .then((resp) => {
                                dispatch(setInterests())
                                dispatch(showSnackbar({message: `tag (${name}) added successfully`, type: "success"}))
                            })
                            .catch((error) => {
                                console.log(error)
                                dispatch(showSnackbar({message: `Error!! Somthing went wrong`, type: "error"}))
                            })
                    } else {
                        setEnglishError(true)
                    }
                } else {
                    setSpanishError(true)
                }
            } else {
                setTextError(true)
            }
        }
    }

    const prepareData = () => {
        let arr = []
        let obj1 = {languageCode: "en", data: englishData}
        let obj2 = {languageCode: "es", data: spanishData}
        arr.push(obj1)
        arr.push(obj2)
        let data = {
            text: text,
            translations: arr
        }
        return data
    }

    const handleKeyDown = (event) => {
        if(event.key === 'Enter'){

        }
    }

    return (
        <Grid container spacing={2} className="tag-form">
            <Grid item xs={12}>
                <span className="box-title">create tag</span>
            </Grid>
            <Grid item xs={12}>
                <TextField
                    className={"input-dark"}
                    type="text"
                    label="text"
                    error={textError}
                    placeholder="tag value"
                    value={text}
                    variant="outlined"
                    onKeyDown={handleKeyDown}
                    onChange={(e) => {
                        setText(e.target.value)
                        e.target.value ? setTextError(false) : setTextError(true)
                    }}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    className={"input-dark"}
                    type="text"
                    error={spanishError}
                    label="EN translation"
                    placeholder="translation to spanish"
                    value={spanishData}
                    variant="outlined"
                    onKeyDown={handleKeyDown}
                    onChange={(e) => {
                            setSpanish(e.target.value)
                            e.target.value ? setSpanishError(false) : setSpanishError(true)
                        }}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    className={"input-dark"}
                    type="text"
                    error={englishError}
                    label="EN translation"
                    placeholder="translation to english"
                    value={englishData}
                    variant="outlined"
                    onKeyDown={handleKeyDown}
                    onChange={(e) => {
                            setEnglish(e.target.value)
                            e.target.value ? setEnglishError(false) : setEnglishError(true)
                        }}
                />
            </Grid>
            <Grid item xs={12} container justify="center">
                {!tag && <Button className="submit-btn" onClick={handleSubmit}>create tag</Button>}
                {tag && tag.id && <Button className="submit-btn" onClick={handleEdit}>save changes</Button>}

            </Grid>
        </Grid>
    )
}

export default TagForm;