import React, {Fragment, useEffect, useState} from 'react';
import {Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@material-ui/core";
import MenuBook from "@material-ui/icons/MenuBook";
import './inbox.scss';
import services from "../../services";
import Modal from "../../components/Modal/Modal";

const InboxPage = () => {
    const [showInfo, setShowInfo] = useState(null);
    const [messages, setMessages] = useState(false);

    useEffect(() => {
        services.inbox.find().then((res) => {
            console.log(res)
            setMessages(res);
        }).catch((err) => {
            console.log(err)
        });
    }, []);

    return (
        <Grid container className="inbox-page">
            <Grid item xs={12}>
                <span className="box-title">Inbox</span>
            </Grid>
            <Grid item container direction="row" justify="center" xs={12}>
                <Table className="custom-table" size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell align="center">Submitted by</TableCell>
                            <TableCell align="center">Subject</TableCell>
                            <TableCell align="center">Date</TableCell>
                            <TableCell align="center"></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {(messages && messages.data) && messages.data.map((row, index) => (
                            <TableRow key={index} onClick={() => setShowInfo(row)}>
                                <TableCell align="center" className="title">{row.name} ({row.email})</TableCell>
                                <TableCell align="center" className="title">{row.subject}</TableCell>
                                <TableCell align="center" className="title">{row.createdAt}</TableCell>
                                <TableCell>
                                    <MenuBook className="edit-icon"
                                              onClick={(e) => {
                                                  setShowInfo(row)
                                              }}/>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </Grid>
            <Modal
                classes={'inbox-page-modal'}
                state={showInfo !== null}
                persist={false}
                max={"md"}
                dark={true}
                radius={false}
                onClose={() => {
                    setShowInfo(null)
                }}>
                {showInfo !== null && (
                    <>
                        <p className={"info-label"}>From:</p>
                        <p className={"info-value"}>{showInfo.name} ({showInfo.email})</p>
                        <p className={"info-label"}>Subject:</p>
                        <p className={"info-value"}>{showInfo.subject}</p>
                        <p className={"info-label"}>Message:</p>
                        <p className={"info-content"}>{showInfo.message}</p>
                    </>
                )}
            </Modal>
        </Grid>
    );
};

export default InboxPage;
