import React from 'react';
import './not-found.scss';
import { Box } from '@material-ui/core';
import SentimentVeryDissatisfiedIcon from '@material-ui/icons/SentimentVeryDissatisfied';

const NotFound = (props) => {
    return (
        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" className="not-found">
            <h1 className="error-code">404</h1>
            <h2 className="error-message"> {props.message ? props.message : "page not found"} <SentimentVeryDissatisfiedIcon className="icon" /> </h2>
        </Box> 
    )
}

export default NotFound;