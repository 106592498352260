const initialState = {
    page: 0,
    total: 0,
    data: [],
    skip: 0
}


const usersReducer = (state = initialState, action) => {
    switch(action.type){
        case 'SET_USERS':
            return {...state, ...action.payload}
        case 'UNSET_USERS':
            return state = initialState
        default:
            return state
    }
}

export default usersReducer;