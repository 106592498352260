import React from 'react'
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import { useSelector } from 'react-redux';

const InterestList = (props) => {
    const list = useSelector(state => state.interests)

    const handleChange = (event, val) => {
        props.handleChange(val);
    }

    return (
        <Autocomplete
            options={list}
            getOptionLabel={option => option.text}
            className={"tag-select input-dark"}
            onChange={handleChange}
            renderInput={params => (
                <TextField
                    {...params}
                    variant="outlined"
                    placeholder="Select Interest"
                    fullWidth
                />
            )}
        />
    )
}


export default InterestList;
