import React, {useMemo, useEffect} from 'react';
import {BrowserRouter as Router, Switch, Route, Redirect} from 'react-router-dom';
import {Grid} from '@material-ui/core';
import CssBaseline from "@material-ui/core/CssBaseline";
import createMuiTheme from "@material-ui/core/styles/createMuiTheme";
import {ThemeProvider} from '@material-ui/core/styles';
import themeData from './helpers/themeData';
import {useSelector, useDispatch} from 'react-redux';
import Login from './pages/Login/Login';
import NotFound from './pages/404NotFound/404NotFound';
import Dashboard from './pages/Dashboard/Dashboard';
import {reAuthenticate} from './store/admin/actions';
import Sidebar from './components/Sidebar/Sidebar';
import UsersPage from './pages/Users/Users';
import EventsPage from './pages/Events/Events';
import TagsPage from './pages/Tags/Tags';
import PromoteDiscoverPage from './pages/PromoteDiscover/PromoteDiscover';
import ExplorePage from './pages/Explore/Explore'
import InboxPage from "./pages/Inbox/InboxPage";
import SubscriptionsPage from "./pages/Subscriptions/SubscriptionsPage";
import TwitchEventSub from "./pages/TwitchEventSub/TwitchEventSub";

const PrivateRoute = ({ component: Component, admin, ...rest}) => (
    <Route {...rest} render={(props) => (
        (isAdmin(admin)) ? <Component {...props} admin={admin} /> : <Redirect to='/login' />
    )} />
)

const SessionRoute = ({ component: Component, admin, ...rest}) => (
    <Route {...rest} render={(props) => (
        (isAdmin(admin)) ?  <Redirect to='/dashboard' /> : <Component {...props} />
    )} />
)

const isAdmin = (user) => {
  if(user && user.id && user.roles.includes('admin')){
    return true
  }
  return false
}

const App = () => {
  const admin = useSelector(state => state.admin)
  const theme = useMemo(() => createMuiTheme(themeData()),[])
  const dispatch = useDispatch()


  useEffect(() => {
    document.title = "Latency Admin Panel"
    dispatch(reAuthenticate())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [admin.id])

  return (
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Router>
          <Grid container className="app">
            { isAdmin(admin) &&
            <Grid item xs={2}>
              <Sidebar admin={admin} />
            </Grid>
            }
            <Grid item xs={isAdmin(admin) ? 10 : 12} className="app-content">
              <Switch>
                <Route exact path="/">
                  <Redirect to="/dashboard" />
                </Route>
                <SessionRoute exact path="/login" component={Login} admin={admin} />
                <PrivateRoute exact path="/dashboard" component={Dashboard} admin={admin} />
                <PrivateRoute exact path="/users" component={UsersPage} admin={admin} />
                <PrivateRoute exact path="/tags" component={TagsPage} admin={admin} />
                <PrivateRoute exact path="/promote-discover" component={PromoteDiscoverPage} admin={admin} />
                <PrivateRoute exact path="/events" component={EventsPage} admin={admin} />
                <PrivateRoute exact path="/explore" component={ExplorePage} admin={admin} />`
                <PrivateRoute exact path="/inbox" component={InboxPage} admin={admin} />
                <PrivateRoute exact path={"/subscriptions"} component={SubscriptionsPage} admin={admin} />
                <PrivateRoute exact path={"/twitch-eventsub"} component={TwitchEventSub} admin={admin} />
                <Route path="*" component={NotFound} />
              </Switch>
            </Grid>
          </Grid>
        </Router>
      </ThemeProvider>
  )
}

export default App;
