import React, {useEffect, useState} from 'react';
import {Grid, Table, TableBody, TableCell, TableHead, TableRow} from "@material-ui/core";
import SubscriptionRow from "../../components/Subscriptions/SubscriptionRow";
import {setSubscriptions} from "../../store/subscriptions/actions";
import {useDispatch, useSelector} from "react-redux";

const SubscriptionsPage = () => {
    const dispatch = useDispatch();
    const { data: result } = useSelector(state => state.subscriptions)

    useEffect(() => {
        dispatch(setSubscriptions());
    }, []);

    return (
        <Grid container className="inbox-page">
            <Grid item xs={12}>
                <span className="box-title">Subscription plans</span>
            </Grid>
            <Grid item xs={12}>
                <Table className="custom-table" size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell align="center">Plan Name</TableCell>
                            <TableCell align="center">Active Subs</TableCell>
                            <TableCell align="center">Total Subs</TableCell>
                            <TableCell align="center"></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {result && result.map((item) => (
                            <SubscriptionRow key={item.id} itemId={item.id} />
                        ))}
                    </TableBody>
                </Table>
            </Grid>
        </Grid>
    );

};

export default SubscriptionsPage;
