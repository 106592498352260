import React from 'react'
import './event-info.scss';
import { Grid, Box } from '@material-ui/core';
import {formatDate} from '../../helpers/dateFormatter';
import UserCard from '../UserInfo/UserInfo';
import { useSelector } from 'react-redux';

const EventInfo = (props) => {
    const openedEvent = useSelector(state => state.openedEvent)
    const dateFormat = "iii d LLLL"
    const timeFormat = "p"

    return (
        <Grid container className="event-info">
            <Grid item xs={6} className="info" container direction="column" justify="center" alignItems="center">
                <span className="title box-title"> {openedEvent.title} </span>
                {openedEvent.id && <span className="time subtitle"> {formatDate(openedEvent.start, dateFormat)} {`(${formatDate(openedEvent.start, timeFormat)} - ${formatDate(openedEvent.end, timeFormat)})`} </span>}

                <UserCard user={openedEvent.owner} />
                <Box className="count-box">
                    <span className="subtitle">guests: <span>{openedEvent.guests ? openedEvent.guests.length : 0}</span> </span>
                    <span className="subtitle">assistants: <span>{openedEvent.assistants ? openedEvent.assistants.length : 0}</span> </span>
                    <span className="subtitle">comments: <span>{openedEvent.comments ? openedEvent.comments.length : 0}</span> </span>
                    <span className="subtitle cats">categories: {openedEvent.categories && openedEvent.categories.map((item, index) =>
                        <span key={index}> #{item.name} </span>
                    )} </span>
                    <span className="subtitle">creation date: <span>{formatDate(openedEvent.createdAt ? openedEvent.createdAt : new Date(), dateFormat)}</span> </span>
                    <span className="subtitle">description: <span>{openedEvent.description}</span> </span>
                </Box>
            </Grid>
            <Grid item xs={6} className="poster">
                <img src="/images/defaultHeader2.png" alt="latency" />
            </Grid>
        </Grid>
    )
}

export default EventInfo;