import React, {useEffect, useState} from "react";
import {TableCell, TableRow} from "@material-ui/core";
import CancelIcon from "@material-ui/icons/Cancel";
import services from "../../services";


const TwitchOrphanSubRow = ({ item, onDelete }) => {
    const onRemoveSub = () => {
        onDelete(item.id);
    };

    return (
        <>
            {(item && item.hasOwnProperty('id')) && (
                <TableRow>
                    <TableCell align="center" className="title">{item.id}</TableCell>
                    <TableCell align="center" className="title">{item.type}</TableCell>
                    <TableCell align="center" className="title">{item.condition?.broadcaster_user_id}</TableCell>
                    <TableCell align="center" className="title">{item.status}</TableCell>
                    <TableCell align={"center"}>
                        <CancelIcon className="edit-icon" onClick={onRemoveSub} />
                    </TableCell>
                </TableRow>
            )}
        </>
    )
};

export default TwitchOrphanSubRow;
