import {createStore, applyMiddleware} from 'redux';
import { persistStore, persistReducer } from 'redux-persist'
import thunk from 'redux-thunk';
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web
import allReducers from './reducers';
import { encryptTransform } from 'redux-persist-transform-encrypt';


const encryptor = encryptTransform({
    secretKey: 'latency-admin'
})


const persistConfig = {
    key: 'latency_admin',
    storage,
    transforms: [encryptor],
}

const persistedReducer = persistReducer(persistConfig, allReducers)
export const store = createStore(persistedReducer, applyMiddleware(thunk))
export const persistor = persistStore(store)
