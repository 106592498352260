import {format} from 'date-fns';
import {formatDistance} from 'date-fns';

const options = {locale: ""}

export const formatDate = (date, form) => {
    return format(new Date(date), form, options)
}

export const timeAgo = (data) => {
    return formatDistance(new Date(data), new Date())
}