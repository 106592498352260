import React, { Fragment, useState } from 'react';
import './user-listing.scss';
import {Box,TablePagination, Table, TableBody, TableContainer, TableCell, TableHead, Paper, TableRow, Avatar, Tooltip} from '@material-ui/core';
import {useDispatch } from 'react-redux';
import {setUsers} from '../../store/users/actions';
import {openUserInfoModal} from '../../store/userInfoModal/actions';
import {setOpenedProfile} from '../../store/openedProfile/actions';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import BlockIcon from '@material-ui/icons/Block';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import ConfirmationBox from '../ConfirmationBox/ConfirmationBox';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import services from '../../services';
import {showSnackbar} from '../../store/snackbar/actions';
import Modal from '../Modal/Modal';
import UserForm from '../Forms/UserForm/UserForm'

const UserListCard = (props) => {
    const {users} = props;
    const [confirmDialog, setConfirmDialog] = useState(false);
    const [userModal, setUserModal] = useState(false);
    const dispatch = useDispatch();
    const [selectedDeleteUser, setSelectedDeleteUser] = useState({});
    const [selectedEditUser, setSelectedEditUser] = useState({});


    const showInfo = (data) => {
        console.log(
            `https://latencyapp.com/${data.avatar}`
        )
        dispatch(openUserInfoModal())
        dispatch(setOpenedProfile(data.id))
    }

    const deleteUser = () => {
        services.users.remove(selectedDeleteUser.id)
            .then((resp) => {
                dispatch(setUsers())
                setConfirmDialog(false)
            })
            .catch((error) => {
                console.log(error)
                dispatch(showSnackbar({message: "Something went wrong", type: "error"}))
            })
    }

    const blockUser = (e, item) => {
        e.stopPropagation()
        if(item.id){
            let data = {expire_ban: new Date(), subject_banned: "You are current banned"}
            services.users.patch(item.id, data)
                .then((resp) => {
                    dispatch(setUsers())
                })
                .catch((error) => {
                    console.log(error)
                })
        }
    }

    const unblockUser = (e, item) => {
        e.stopPropagation()
        if(item.id){
            let data = {expire_ban: null, subject_banned: ""}
            services.users.patch(item.id, data)
                .then((resp) => {
                    dispatch(setUsers())
                })
                .catch((error) => {
                    console.log(error)
                })
        }
    }

    return (
        <Fragment>
            <TableContainer component={Paper} className="user-listing">
                <Table className="custom-table" size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell align="center" size="small"></TableCell>
                            <TableCell align="center">Nickname / Full Name</TableCell>
                            <TableCell align="center">Email</TableCell>
                            <TableCell align="center">Gender</TableCell>
                            <TableCell align="center">Location</TableCell>
                            <TableCell align="center">Account Verified</TableCell>
                            <TableCell align="center"></TableCell>
                            <TableCell align="center"></TableCell>
                            <TableCell align="center"> <PersonAddIcon className="edit-icon" onClick={() => setUserModal(true)} /></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {users.data && users.data.map((row, index) => (
                            <TableRow key={index} onClick={() => showInfo(row)}>
                                <TableCell align="center" size="small"><Avatar className="avatar" src={`https://latencyapp.com${row.avatar}`} /></TableCell>
                                <TableCell align="center">{row.nickname} / {row.firstName + " " + row.lastName} </TableCell>
                                <TableCell align="center">{row.email}</TableCell>
                                <TableCell align="center">{row.gender}</TableCell>
                                <TableCell align="center">{row.location}</TableCell>
                                <TableCell align="center" className={row.account_verified ? "yes" : "no"}>{row.account_verified ? "verified" : "not verified"}</TableCell>
                                <TableCell align="center">
                                    <Tooltip placement="top" title="Edit user">
                                        <EditIcon className="edit-icon"
                                            onClick={(e) => {
                                                e.stopPropagation()
                                                setSelectedEditUser(row)
                                                setUserModal(true)
                                            }} />
                                    </Tooltip>
                                </TableCell>
                                <TableCell align="center">
                                    {!row.expire_ban &&
                                        <Tooltip placement="top" title="Block user">
                                            <BlockIcon className="del-icon" onClick={(e) => blockUser(e,row)} />
                                        </Tooltip>
                                    }
                                    {row.expire_ban &&
                                        <Tooltip placement="top" title="Unblock user">
                                            <LockOpenIcon className="edit-icon" onClick={(e) => unblockUser(e,row)} />
                                        </Tooltip>
                                    }
                                </TableCell>
                                <TableCell align="center">
                                    <Tooltip placement="top" title="Delete user">
                                        <DeleteIcon className="del-icon"
                                            onClick={(e) => {
                                            e.stopPropagation()
                                            setConfirmDialog(true)
                                            setSelectedDeleteUser(row)}} />
                                    </Tooltip>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Box className="listing-pagination">
                <TablePagination
                    component="div"
                    count={users.total}
                    rowsPerPage={10}
                    rowsPerPageOptions={[10,15,20]}
                    backIconButtonText="Prev"
                    nextIconButtonText="Next"
                    page={users.page}
                    onChangePage={(e,page) => dispatch(setUsers(page))}/>
            </Box>
            <ConfirmationBox
                state={confirmDialog}
                persist={false}
                max={"sm"}
                dark={true}
                radius={false}
                onAgree={() => deleteUser()}
                onDisagree={() => setConfirmDialog(false)}
                onClose={() => setConfirmDialog(false)}>

                <Fragment>Are you sure, you want to delete this user?</Fragment>
            </ConfirmationBox>
            <Modal
                state={userModal}
                persist={false}
                max={"md"}
                dark={true}
                radius={false}
                onClose={() => {
                    setUserModal(false)
                    setSelectedEditUser({})
                }}>

                <UserForm user={selectedEditUser}
                    onClose={() => {
                        setUserModal(false)
                        setSelectedEditUser({})
                    }}/>
            </Modal>
        </Fragment>
    )
}

export default UserListCard;
