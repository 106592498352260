import React from 'react';
import './snackbar.scss';
import {Snackbar} from '@material-ui/core';
import {useSelector, useDispatch} from 'react-redux';
import {hideSnackbar} from '../../store/snackbar/actions';

const Popup = () => {
    const snackbar = useSelector(state => state.snackbar)
    const dispatch = useDispatch()

    return (
        <Snackbar
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            className={" snackbar " + (snackbar ? snackbar.type : "info")}
            open={snackbar ? snackbar.open : false}
            onClose={() => dispatch(hideSnackbar())}
            autoHideDuration={3000}
            ContentProps={{
                'aria-describedby': 'message-id',
            }}
            message={<span id="message-id">{snackbar ? snackbar.message : ""}</span>}
        />
    )
}

export default Popup;