const initailState = false

const userInfoModalReducer = (state = initailState, action) => {
    switch(action.type){
        case 'OPEN_USER_INFO_MODAL':
            return state = true
        case 'CLOSE_USER_INFO_MODAL':
            return state = initailState
        default:
            return state
    }
}

export default userInfoModalReducer;