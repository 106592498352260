import React, { Fragment } from 'react';
import './explore-event-card.scss';
import {Box} from '@material-ui/core';
import { formatDate } from '../../helpers/dateFormatter';
import Chip from "@material-ui/core/Chip";
import StarIcon from '@material-ui/icons/Star';
import { formatDistanceToNow, isPast } from 'date-fns';

const ExploreEventCard = (props) => {
    const {event} = props

    return (
        <Box className={"explore-event-card" + ( isPast(new Date(event.start)) ? ' ispast' : '' )} bgcolor="grid.main">
            <Box className="event-card-content" display="flex" flexDirection="column" justifyContent="center">
                <span className="title">
                    {event.explore_event.highlight === false ? event.title : <Chip color="primary" size="small" style={{color: 'white'}} outline={"true"} icon={<StarIcon style={{ color: 'white' }} />} label={event.title} />}
                </span>
                <span className="owner">Owner: {event.owner ? event.owner.nickname : ""} </span>
                <Box display="flex" flexDirection="row" justifyContent="space-between">
                    <Fragment>
                        <span className="date">{`${formatDate(event.start, "dd-MM-yyyy HH:mm")} `} ({`${formatDistanceToNow(new Date(event.start), { addSuffix: true })}`})</span>
                    </Fragment>
                    <Fragment>
                        <span className="date">weight: {event.explore_event.weight}</span>
                    </Fragment>
                </Box>
            </Box>
        </Box>
    )
}

export default ExploreEventCard;
