import React, {useEffect, useState} from 'react';
import {Grid, TableCell, TableRow, TextField} from "@material-ui/core";
import {useSelector} from "react-redux";
import services from "../../services";
import EditIcon from '@material-ui/icons/Edit';
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';
import RedeemIcon from '@material-ui/icons/Redeem';
import Modal from "../Modal/Modal";
import SubscriptionInfoForm from "./SubscriptionInfoForm";

const SubscriptionRow = ({itemId}) => {
    const { data: subs } = useSelector(state => state.subscriptions)
    const [item, setItem] = useState({});
    const [usersSubs, setUsersSubs] = useState({
        active: 0,
        total: 0
    });

    const [openInfo, setOpenInfo] = useState(false);
    const [openUsers, setOpenUsers] = useState(false);
    const [openVouchers, setOpenVouchers] = useState(false);

    useEffect(() => {
        let found = subs.find(e => e.id === itemId);
        if(found) {
            services.userSubscription.find({
                query: {
                    subscriptionPlanId: itemId
                }
            }).then((res) => {
                setUsersSubs({
                    data: res,
                    active: 0,
                    total: res.length || 0
                });
            }).then(() => setItem(found));
        }
    }, [itemId, subs]);

    const toggleInfo = () => {
        setOpenInfo(!openInfo);
    }

    const toggleUsers = () => {
        setOpenUsers(!openUsers);
    };

    const toggleVoucherCodes = () => {
      setOpenVouchers(!openVouchers);
    };

    return (
        <>
            {(item && item.hasOwnProperty('id')) && (
                <TableRow>
                    <TableCell align="center" className="title">{item.name} ({item.price}€)</TableCell>
                    <TableCell align="center" className="title">{usersSubs.active}</TableCell>
                    <TableCell align="center" className="title">{usersSubs.total}</TableCell>
                    <TableCell>
                        <SupervisedUserCircleIcon className="edit-icon" onClick={toggleUsers}/>
                        <RedeemIcon className="edit-icon" onClick={toggleVoucherCodes}/>
                        <EditIcon className="edit-icon" onClick={toggleInfo}/>
                    </TableCell>
                </TableRow>
            )}
            <Modal
                classes={'inbox-page-modal'}
                state={openInfo}
                persist={false}
                max={"md"}
                dark={true}
                radius={false}
                onClose={toggleInfo}>
                <SubscriptionInfoForm itemId={itemId} />
            </Modal>
        </>
    )
        ;
};

export default SubscriptionRow;
