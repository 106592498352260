import React, {useEffect, useState} from "react";
import {TableCell, TableRow} from "@material-ui/core";
import CancelIcon from "@material-ui/icons/Cancel";
import services from "../../services";


const TwitchEventSubRow = ({ recordId, onDelete }) => {
    const [item, setItem] = useState({});

    useEffect(() => {
        services.twitchEventSub.get(recordId).then((res) => {
           setItem(res);
        });
    }, [recordId]);

    const onRemoveSub = () => {
        onDelete(recordId);
    };

    return (
        <>
            {(item && item.hasOwnProperty('id')) && (
                <TableRow>
                    <TableCell align="center" className="title">{item.userId}</TableCell>
                    <TableCell align="center" className="title">{item.type}</TableCell>
                    <TableCell align="center" className="title">{item.broadcaster_user_id}</TableCell>
                    <TableCell align="center" className="title">{item.twitch.status}</TableCell>
                    <TableCell align={"center"}>
                        <CancelIcon className="edit-icon" onClick={onRemoveSub} />
                    </TableCell>
                </TableRow>
            )}
        </>
    )
};

export default TwitchEventSubRow;
