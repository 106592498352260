const themeData = (darkTheme) => {
    return {
        palette: {
            background: {
                default: '#23304B',
                paper: 'rgb(25, 38, 61)',
            },
            header: {
                main: '#23304B',
            },
            grid: {
                main: '#283654',
                content: '#31425F',
                side: '#1a1a1a',
                dark: 'rgb(25, 38, 61)',
            },
            primary: {
                main: '#00D1BF',
            },
            secondary: {
                main: '#00D1BF',
            },
            text: {
                primary: '#efefef'
            },
            search:{
                main: '#23304B',
            },
            action: {
                hover: '#23304B',
                active: '#072638',
                hoverOpacity: 0,
            },
            typography:{
                main: '#283654',
            },
        },
    }
}

export default themeData;