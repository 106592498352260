import React, {useState, useCallback } from 'react';
import './image-cropper.scss';
import Cropper from 'react-easy-crop'
import getCroppedImg from '../../helpers/imageCropper';
import {Box, Slider, Button} from '@material-ui/core';

const ImageCropper = (props) => {
    const {poster, cropper} = props
    const [crop, setCrop] = useState({x:0, y:0})
    const [zoom, setZoom] = useState(3)
    const [cropPixels, setCropPixels] = useState(0)

    const onCropChange = (crop) => {
        setCrop(crop)
    }

    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
        setCropPixels(croppedAreaPixels)
    }, [])

    const onZoomChange = (event, newValue) => {
        setZoom(newValue ? newValue : event)
    }

    const cropImage = useCallback(async () => {
        try {
            const image = await getCroppedImg(poster, cropPixels)
            props.onCrop(image)
            props.onClose()
        } catch (e) {}
    }, [cropPixels, poster, props])

    return (
        <Box className={"image-cropper " + props.className} display="flex" flexDirection="column" justifyContent="center" alignItems="center">
            <Box className="crop-box">
                <Cropper
                    image={poster}
                    crop={crop}
                    zoom={zoom}
                    maxZoom={6}
                    showGrid={false}
                    aspect={4/3}
                    cropSize={cropper}
                    onCropChange={onCropChange}
                    onCropComplete={onCropComplete}
                    onZoomChange={onZoomChange}/>
            </Box>
            <Box className="crop-actions" display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                <Slider
                    value={zoom}
                    color="secondary"
                    min={1}
                    max={5}
                    step={.10}
                    onChange={onZoomChange}
                    valueLabelDisplay="auto"/>
                <Box display="flex" flexDirection="row" justifyContent="center" alignItems="center">
                    <Button className="cancel-btn" onClick={props.onClose}>Cancel</Button>
                    <Button className="crop-btn" onClick={cropImage}>Crop</Button>
                </Box>
            </Box>
        </Box>
    )
}

export default ImageCropper;
