import React, {useEffect, useState} from 'react';
import services from "../../services";
import {Grid, Table, TableBody, TableCell, TableHead, TableRow} from "@material-ui/core";
import TwitchEventSubRow from "../../components/TwitchEventSub/TwitchEventSubRow";
import TwitchOrphanSubRow from "../../components/TwitchEventSub/TwitchOrphanSubRow";
import Button from "@material-ui/core/Button";

const TwitchEventSub = () => {
    const [results, setResults] = useState([]);
    const [orphans, setOrphans] = useState([]);

    useEffect(() => {
        services.twitchEventSub.find({}).then((res) => {
            setResults(res.data);
        })
    }, []);

    const fetchOrphans = () => {
        services.twitchEventSub.find({
            query: {
                orphans: true
            }
        }).then((res) => {
            setOrphans(res.orphans);
        })
    };

    const handleOnDelete = (recordId) => {
        services.twitchEventSub.remove(recordId).then((res) => {
            setResults(
                results.filter(i => i.id !== recordId)
            );
        });
    }

    const handleOnDeleteOrphan = (orphanId) => {
        services.twitchEventSub.remove(orphanId, {
            query: {
                orphan: true
            }
        }).then((res) => {
            setOrphans(
                orphans.filter(i => i.id !== orphanId)
            );
        });
    }

    return (
        <Grid container className="inbox-page">
            <Grid item xs={12}>
                <span className="box-title">Twitch EventSub</span>
            </Grid>
            <Grid item xs={12}>
                <Table className="custom-table" size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell align="center">UserId</TableCell>
                            <TableCell align="center">Type</TableCell>
                            <TableCell align="center">Broadcaster UserId</TableCell>
                            <TableCell align="center">Status</TableCell>
                            <TableCell align="center">Action</TableCell>
                            <TableCell align="center"></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {results && results.map((item) => (
                            <TwitchEventSubRow key={item.id} recordId={item.id} onDelete={handleOnDelete} />
                        ))}
                    </TableBody>
                </Table>
            </Grid>

            <Grid item xs={12}>
                <span className="box-title">Orphan subscriptions</span>
            </Grid>
            <Grid item xs={12}>
                <Table className="custom-table" size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell align="center">SubId</TableCell>
                            <TableCell align="center">Type</TableCell>
                            <TableCell align="center">Broadcaster UserId</TableCell>
                            <TableCell align="center">Status</TableCell>
                            <TableCell align="center">Action</TableCell>
                            <TableCell align="center"></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell colSpan={6}>
                                <Button variant={"contained"} color={"primary"} onClick={fetchOrphans}>FETCH ORPHANS</Button>
                            </TableCell>
                        </TableRow>
                        {orphans && orphans.map((item) => (
                            <TwitchOrphanSubRow key={item.id} item={item} onDelete={handleOnDeleteOrphan} />
                        ))}
                    </TableBody>
                </Table>
            </Grid>
        </Grid>
    )
};

export default TwitchEventSub;
