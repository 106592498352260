import services from '../../services/';

export const setExplore = () => {
    return dispatch => {
        services.explore.find()
            .then((resp) => {
                dispatch({
                    type: 'SET_EXPLORE',
                    payload: resp.data
                })
            })
            .catch((error) => {
                console.log(error)
            })
    }
}

export const unsetExplore = () => {
    return dispatch => {
        dispatch({type: 'UNSET_EXPLORE'})
    }
}
