import services from '../../services/';
import client from '../../services/client';

export const login = (data, redirect, onError) => {
    const {email, password} = data
    return dispatch => {
        client.authenticate({strategy: 'local', email, password})
            .then((resp) => {
                dispatch({
                    type: 'LOGIN',
                    payload: resp.user
                })
                redirect()
            }).catch((error) => {
                onError()
                console.log(error)
            })
    }
}

export const register = (data, redirect, onError) => {
    return dispatch => {
        services.users.create(data)
            .then(() => {
                dispatch(login(data, redirect))
            })
            .catch((error) => {
                onError()
                console.log(error)
            })
    }
}

export const logout = () => {
    return dispatch => {
        client.logout()
            .then(() => {
                dispatch({type: 'LOGOUT'})
            })
    }
}

export const refreshSession = (data) => {
    return dispatch => {
        dispatch({type: 'REFRESH_SESSION', payload: data})
    }
}

export const reAuthenticate = () => {
    return dispatch => {
        client.reAuthenticate()
            .then((resp) => {
                dispatch(refreshSession(resp.user))
            })
            .catch((error) => {
                dispatch(logout())
            })
    }
}
