import services from '../../services';

export const setOpenedProfile = (id) => {
    return dispatch => {
        services.users.get(id)
            .then((resp) => {
                dispatch({
                    type: 'SET_OPENED_PROFILE',
                    payload: resp
                })
            })
    }
}

export const unsetOpenedProfile = () => {
    return dispatch => {
        dispatch({type: 'UNSET_OPENED_PROFILE'})
    }
}
