import React, {useState} from 'react';
import './login.scss';
import {Box} from '@material-ui/core';
import LoginForm from '../../components/Forms/LoginForm/LoginForm';
import Logo from '../../components/Logo/Logo';
import {login} from '../../store/admin/actions';
import {useDispatch} from 'react-redux';
import {useHistory} from 'react-router-dom';

const Login = () => {
    const [error, setError] = useState(false)
    const dispatch = useDispatch()
    const history = useHistory()

    const handleSubmit = (params) => {
        dispatch(login(params, redirect, onError))
    }

    const redirect = () => {
        history.push('/dashboard')
    }

    const onChange = () => {
        setError(false)
    }

    const onError = () => {
        setError(true)
    }

    return (
        <Box bgcolor="background.default" className="login-page" display="flex" flexDirection="column" justifyContent="center" alignItems="center">
            <Logo className="login-logo" />
            <span className="box-title">Admin Panel Login</span>
            <LoginForm onSubmit={handleSubmit} onChange={onChange} error={error} />
        </Box>
    )
}

export default Login;
