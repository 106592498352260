import React, {useEffect, useState} from 'react';
import './discover-form.scss';
import {Grid, Box, TextField, Button} from '@material-ui/core';
import HourglassFullIcon from '@material-ui/icons/HourglassFull';
import InterestList from './InterestList';
import UserList from './UserList';
import {useDispatch} from 'react-redux';
import services from '../../../services';
import {showSnackbar} from '../../../store/snackbar/actions';
import {setDiscoverUsers} from '../../../store/discoverUsers/actions';
import {setInterests, unsetInterests} from "../../../store/interests/actions";

const DiscoverForm = (props) => {
    const {user} = props
    const [description, setDescription] = useState(user.id ? user.description : "")
    const [weight, setWeight] = useState(user.id ? user.weight : "")
    const [isLatency, setIsLatency] = useState(user.id ? user.is_latency : false)
    const [selectedInterest, setSelectedInterest] = useState({})
    const [selectedUser, setSelectedUser] = useState({})
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(setInterests())
        return () => {
            dispatch(unsetInterests())
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const submit = () => {
        let data = {
            description,
            weight,
            is_latency: isLatency,
            interestId: selectedInterest.id,
            userId: selectedUser.id
        }
        if (description) {
            services.discover.create(data)
                .then((resp) => {
                    dispatch(showSnackbar({message: `Promotion Created Cuccessfully`, type: "success"}))
                    props.onClose()
                    dispatch(setDiscoverUsers())
                })
                .catch((error) => {
                    console.log(error)
                    dispatch(showSnackbar({message: `Error!! Somthing went wrong`, type: "error"}))
                })
        }
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            submit()
        }
    }

    const handleIntChange = (val) => {
        setSelectedInterest(val)
    }

    const handleUserChange = (val) => {
        setSelectedUser(val)
    }

    return (
        <Grid container className="discover-form">
            <Grid container>
                <Grid item xs={12}>
                    <span className="box-title">promote user</span>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        multiline
                        rows={7}
                        className={"input-dark input-textarea"}
                        type="text"
                        placeholder="Description"
                        value={description}
                        variant="outlined"
                        onKeyDown={handleKeyDown}
                        onChange={(e) => setDescription(e.target.value)}
                    />
                </Grid>
            </Grid>
            <Grid spacing={2} container>
                <Grid item xs={6}>
                    <Box
                        className={"checkbox-dark " + (isLatency ? " selected" : " ")}
                        display="flex"
                        flexDirection="row"
                        justifyContent="justify-start"
                        alignItems="center"
                        onClick={() => setIsLatency(!isLatency)}>

                        <HourglassFullIcon color="secondary" className="icon"/>
                        <label>is latency</label>
                    </Box>
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        className={"input-dark"}
                        type="number"
                        placeholder="Enter weight"
                        value={weight}
                        variant="outlined"
                        onKeyDown={handleKeyDown}
                        onChange={(e) => setWeight(e.target.value)}
                    />
                </Grid>
            </Grid>
            <Grid spacing={2} container>
                <Grid item xs={6}>
                    <InterestList defaultValue={props.defaultInterest} handleChange={handleIntChange}/>
                </Grid>
                <Grid item xs={6}>
                    <UserList initialData={props.user} handleChange={handleUserChange}/>
                </Grid>
            </Grid>
            <Grid item xs={12} container justify="center">
                <Button className="submit-btn" onClick={submit}>Promote User</Button>
            </Grid>
        </Grid>
    )
}

export default DiscoverForm;
