import React from 'react';
import './dashboard-counts.scss';
import { Box } from '@material-ui/core';
import {useSelector} from "react-redux";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCalendar, faFire, faUserAstronaut, faUsers, faUserSecret} from "@fortawesome/free-solid-svg-icons";

const DashboardCounts = (props) => {
    const counts = useSelector(state => state.dashboard)
    const { totalUsers, totalEvents, newSignups, newEvents, currentActiveLoggedUsers, currentActiveGuestUsers } = counts;

    return (
        <Box className="dashboard-counts" display="flex" flexDirection="row" flexWrap="wrap" justifyContent="space-between">
            <Box bgcolor="grid.dark" className="count" display="flex" flexDirection="column" justifyContent="space-between" alignItems="center">
                <span className="numb">{totalUsers}</span>
                <span className="subtitle"><FontAwesomeIcon icon={faUsers} /> total users</span>

                <Box bgcolor="grid.dark" className="count" display="flex" flexDirection="row" justifyContent="center" alignItems="center">
                    <span className="numb"><FontAwesomeIcon icon={faFire} /> {newSignups}</span>
                    <span className="msubtitle">new signups in last 24h</span>
                </Box>
            </Box>

            <Box bgcolor="grid.dark" className="count" display="flex" flexDirection="column" justifyContent="space-between" alignItems="center">
                <span className="numb">{totalEvents}</span>
                <span className="subtitle"><FontAwesomeIcon icon={faCalendar} /> total events</span>

                <Box bgcolor="grid.dark" className="count" display="flex" flexDirection="row" justifyContent="center" alignItems="center">
                    <span className="numb"><FontAwesomeIcon icon={faFire} /> {newEvents}</span>
                    <span className="msubtitle">new events in last 24h</span>
                </Box>
            </Box>
            <Box bgcolor="grid.dark" className="count" display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                <Box className="dblcount" display="flex" flexDirection="row" justifyContent="space-around">
                    <span className="group">
                        <span className="numb">{currentActiveLoggedUsers}</span>
                        <FontAwesomeIcon className="iconNumb" icon={faUserAstronaut} />
                    </span>
                    <span className="group">
                        <span className="numb">{currentActiveGuestUsers}</span>
                        <FontAwesomeIcon className="iconNumb" icon={faUserSecret} />
                    </span>
                </Box>
                <span className="subtitle">current active users</span>
            </Box>
        </Box>
    )
}

export default DashboardCounts;
