const initialState = {
    page: 0,
    total: 0,
    data: [],
    skip: 0
}

const eventsReducer = (state = initialState, action) => {
    switch(action.type){
        case 'SET_EVENTS':
            return {...state, ...action.payload}
        case 'UNSET_EVENTS':
            return state = initialState
        default:
            return state
    }
}

export default eventsReducer;