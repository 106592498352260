import React, { useState, useEffect } from 'react';
import './explore.scss';
import { Box } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import ExploreInterestsForm from '../../components/Explore/ExploreTopbar/ExploreTopbar';
import ExploreUserEventForm from '../../components/Explore/ExploreListing/ExploreListing';
import {setExplore, unsetExplore} from '../../store/explore/actions';

const Explore = (props) => {
    const explore = useSelector(state => state.explore)
    const [selected, setSelected] = useState({})
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(setExplore())
        return () => {
            dispatch(unsetExplore())
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if(explore.length > 0){
            let found = explore.find(item => item && selected && item.id === selected.id)
            setSelected(found)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [explore])

    const onChange = (item) => {
        setSelected(item)
    }

    return (
        <Box display="flex" flexDirection="row" justifyContent="center" className="explore-page" flexWrap="wrap">
            <Box mb={4} className="header" display="flex" flexDirection="row" justifyContent="center">
                <span className="box-title">Manage Explore Page</span>
            </Box>
            <ExploreInterestsForm onChange={onChange} />
            {selected && selected.id &&
                <ExploreUserEventForm events={explore.events} users={explore.users} data={selected} />
            }

        </Box>
    )
}

export default Explore;