import React, { useState } from 'react';
import './search-bar.scss';
import { Box, TextField } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import services from '../../services';

const SearchBar = (props) => {
    const {type, onSearch, onReset} = props
    const [name, setName] = useState("")

    const handleChange = (e) => {
        setName(e.target.value)
        if(e.target.value){
            if(e.target.value.length >= 2){
                let query = {
                    text: e.target.value,
                    admin: true,
                }
                if(type === "user"){query.users = true}
                if(type === "event"){query.events = true}
                services.search.find({query: query})
                    .then((resp) => {
                        let data ={
                            page: 0,
                            total: resp.length,
                            data: resp
                        }
                        onSearch(data)
                    })
                    .catch((error) => {
                        console.log(error)
                    })
            }
        } else {
            onReset()
        }
    }

    return (
        <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center" className="search-bar">
            <TextField
                className={"search-input"}
                type="text"
                placeholder={props.placeholder}
                value={name}
                variant="outlined"
                onChange={handleChange}
            />
            <SearchIcon className="search-icon" onClick={props.openModal} />
        </Box>
    )
}

export default SearchBar;