import React from 'react';
import './sidebar.scss';
import { Box } from '@material-ui/core';
import {Link, useHistory} from 'react-router-dom';
import Logo from '../Logo/Logo';
import Menu from './Menu';
import Modal from '../Modal/Modal';
import {useSelector, useDispatch} from 'react-redux';
import {closeEventInfoModal} from '../../store/eventInfoModal/actions';
import EventInfo from '../EventInfo/EventInfo';
import Snackbar from '../Snackbar/Snackbar';
import {closeUserInfoModal} from '../../store/userInfoModal/actions';
import UserInfo from '../UserInfo/UserInfo';

const Sidebar = (props) => {
    const eventInfoModal = useSelector(state => state.eventInfoModal)
    const userInfoModal = useSelector(state => state.userInfoModal)
    const dispatch = useDispatch()
    const history = useHistory()

    const closeEventInfo = () => {
        dispatch(closeEventInfoModal())
        history.push('/events')
    }

    const closeUserInfo = () => {
        dispatch(closeUserInfoModal())
    }

    return (
        <Box bgcolor="grid.side" className="sidebar">

            <Box minHeight="15%" maxHeight="15%" minWidth="100%" display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                <Link to="/dashboard"><Logo variant="green" className="navbar-logo" /></Link>
                <span className={'navbar-version'}>v{process.env.REACT_APP_VERSION}</span>
            </Box>
            <Box minHeight="60%" maxHeight="60%" minWidth="100%" display="flex" flexDirection="column" justifyContent="flex-start" alignItems="center">
                <Menu />
            </Box>
            <Modal
                state={eventInfoModal ? eventInfoModal : false}
                persist={false}
                max={"md"}
                dark={true}
                radius={false}
                onClose={closeEventInfo}>

                <EventInfo onClose={closeEventInfo} />
            </Modal>
            <Modal
                state={userInfoModal ? userInfoModal : false}
                persist={false}
                max={"sm"}
                dark={true}
                radius={false}
                onClose={closeUserInfo}>

                <UserInfo onClose={closeUserInfo} />
            </Modal>
            <Snackbar />
        </Box>
    )
}

export default Sidebar;
