import {combineReducers} from 'redux';
import adminReducer from './admin/reducer';
import loaderReducer from './loader/reducer';
import snackbarReducer from './snackbar/reducer';
import eventInfoModalReducer from './eventInfoModal/reducer';
import interestsReducer from './interests/reducer';
import discoverUsersReducer from './discoverUsers/reducer';
import dashboardReducer from './dashboard/reducer';
import openedEventReducer from './openedEvent/reducer';
import usersReducer from './users/reducer';
import eventsReducer from './events/reducer';
import exploreReducer from './explore/reducer';
import userInfoModalReducer from './userInfoModal/reducer';
import openedProfileReducer from './openedProfile/reducer';
import subscriptionsReducer from "./subscriptions/reducer";

const allRedcuers = combineReducers({
    admin: adminReducer,
    loader: loaderReducer,
    snackbar: snackbarReducer,
    eventInfoModal: eventInfoModalReducer,
    userInfoModal: userInfoModalReducer,
    interests: interestsReducer,
    discoverUsers: discoverUsersReducer,
    dashboard: dashboardReducer,
    openedEvent: openedEventReducer,
    openedProfile: openedProfileReducer,
    users: usersReducer,
    events: eventsReducer,
    explore: exploreReducer,
    subscriptions: subscriptionsReducer
})

export default allRedcuers;
