import services from '../../services/';

export const setDashboard = (data) => {
    return dispatch => {
        services.dashboard.find()
            .then((resp) => {
                dispatch({
                    type: 'SET_DASHBOARD',
                    payload: resp
                })
            })
            .catch((error) => {
                console.log(error)
            })
    }
}

export const unsetDashboard = () => {
    return dispatch => {
        dispatch({type: 'UNSET_DASHBOARD'})
    }
}
