import React, {useState} from "react";
import './user-form.scss';
import {
    Grid,
    TextField,
    Select,
    MenuItem,
    FormControl,
    Button,
    IconButton,
    InputAdornment,
    OutlinedInput
} from "@material-ui/core";
import {MuiPickersUtilsProvider, KeyboardDatePicker} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import services from "../../../services";
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import {useDispatch} from "react-redux";
import {setUsers} from "../../../store/users/actions";
import InputLabel from "@material-ui/core/InputLabel";

const UserForm = (props) => {
    const {user} = props
    const [nickname, setNick] = useState(user.nickname ? user.nickname : "")
    const [email, setEmail] = useState(user.email ? user.email : "")
    const [password, setPassword] = useState("")
    const [firstName, setFirstname] = useState(user.firstName ? user.firstName : "")
    const [lastName, setLastname] = useState(user.lastName ? user.lastName : "")
    const [biography, setBoigraphy] = useState(user.biography ? user.biography : "")
    const [website, setWebsite] = useState(user.website ? user.website : "")
    const [location, setLocation] = useState(user.location ? user.location : "")
    const [gender, setGender] = useState(user.gender ? user.gender : "male")
    const [birthday, setBirthday] = useState(user.birthday ? new Date(user.birthday) : new Date())
    const [showPassword, setShowPassword] = useState(false)
    const [emailError, setEmailError] = useState(false)
    const [passwordError, setPasswordError] = useState(false)
    const [nickError, setNickError] = useState(false)
    const dispatch = useDispatch()

    const handleSubmit = (e) => {
        let data = {
            email,
            nickname,
            firstName,
            lastName,
            birthday,
            biography,
            website,
            location,
            gender,
        }
        if (password.length > 0) {
            data.password = password
        }
        if (email.length > 0) {
            if (nickname.length > 0) {
                saveData(data);
            } else {
                setNickError(true)
            }
        } else {
            setEmailError(true)
        }
    }

    const saveData = (data) => {
        if (user.id) {
            console.log("editing")
            services.users.patch(user.id, data)
                .then((resp) => {
                    props.onClose()
                    dispatch(setUsers())
                })
                .catch((error) => {
                    console.log(error)
                })
        } else {
            console.log("editing")
            services.users.create(data)
                .then((resp) => {
                    props.onClose()
                    dispatch(setUsers())
                })
                .catch((error) => {
                    console.log(error)
                })
        }
    }

    return (
        <div>
            <Grid container spacing={1} className="user-form">
                <Grid item xs={12}>
                    <span className="box-title">add new user</span>
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        fullWidth
                        required
                        label={"E-mail Address"}
                        className="input-dark"
                        type="email"
                        error={emailError}
                        placeholder="Email"
                        value={email}
                        variant="outlined"
                        onChange={(e) => {
                            setEmail(e.target.value)
                            e.target.value ? setEmailError(false) : setEmailError(true)
                        }}
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        fullWidth
                        required
                        label={"Nickname"}
                        className="input-dark"
                        type="text"
                        error={nickError}
                        placeholder="Nickname"
                        value={nickname}
                        variant="outlined"
                        onChange={(e) => {
                            setNick(e.target.value)
                            e.target.value ? setNickError(false) : setNickError(true)
                        }}
                    />
                </Grid>
                <Grid item xs={4}>
                    <OutlinedInput
                        fullWidth
                        error={passwordError}
                        className="input-dark"
                        type={showPassword ? "text" : "password"}
                        placeholder="Password"
                        value={password}
                        variant="outlined"
                        onChange={(e) => {
                            setPassword(e.target.value)
                        }}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={() => setShowPassword(!showPassword)}>
                                    {showPassword ? <Visibility className="edit-icon"/> :
                                        <VisibilityOff className="edit-icon"/>}
                                </IconButton>
                            </InputAdornment>
                        }
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        fullWidth
                        label={"First name"}
                        className="input-dark"
                        type="text"
                        placeholder="First name"
                        value={firstName}
                        variant="outlined"
                        onChange={(e) => setFirstname(e.target.value)}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        fullWidth
                        label={"Last name"}
                        className="input-dark"
                        type="text"
                        placeholder="Last name"
                        value={lastName}
                        variant="outlined"
                        onChange={(e) => setLastname(e.target.value)}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <TextField
                        fullWidth
                        label={"Biography"}
                        multiline
                        rows={7}
                        className="input-textarea input-dark"
                        type="text"
                        placeholder="Biography"
                        value={biography}
                        variant="outlined"
                        onChange={(e) => setBoigraphy(e.target.value)}
                    />
                </Grid>
                <Grid item xs={6} sm={3} md={3} lg={3}>
                    <FormControl variant="outlined" className={"select-input input-dark"}>
                        <InputLabel id="gender-select-label">Gender</InputLabel>
                        <Select
                            labelId="gender-select-label"
                            value={gender}
                            onChange={(e) => setGender(e.target.value)}>
                            <MenuItem value="male"> Male </MenuItem>
                            <MenuItem value="female">Female</MenuItem>
                            <MenuItem value="other">Other</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={6} sm={3} md={3} lg={3}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils} className="input-field input-date">
                        <KeyboardDatePicker
                            required
                            label="Birthday"
                            className="input-dark"
                            placeholder="Birthday"
                            variant="inline"
                            format="dd/MM/yyyy"
                            value={new Date()}
                            onChange={(date) => setBirthday(date)}/>
                    </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs={12} sm={3} md={3} lg={3}>
                    <TextField
                        label={"Website"}
                        fullWidth
                        className="input-dark"
                        type="text"
                        placeholder="website"
                        value={website}
                        variant="outlined"
                        onChange={(e) => setWebsite(e.target.value)}
                    />
                </Grid>
                <Grid item xs={6} sm={3} md={3} lg={3}>
                    <TextField
                        label={"Location"}
                        fullWidth
                        className="input-dark"
                        type="text"
                        placeholder="Location"
                        value={location}
                        variant="outlined"
                        onChange={(e) => setLocation(e.target.value)}
                    />
                </Grid>
                <Grid item xs={12} container justify="center">
                    <Button loading={props.loading} disabled={props.loading} className="submit-btn wide"
                            onClick={(e) => handleSubmit(e)}> {user.id ? "Edit Data" : "Save Data"} </Button>
                </Grid>
            </Grid>
        </div>
    );
}

export default UserForm;
