import React from 'react';
import './tag-listing.scss';
import { Box } from '@material-ui/core';

const TagListing = (props) => {
    const {interests} = props

    return (
        <Box mt={3} minWidth="70%" maxWidth="70%" className="tag-listing">
            {interests && interests.length > 0 && interests.map((item, index) =>
                <span key={index} onClick={() => props.onSelect(item)} className="chip tag"> {item.text} </span>
            )}
        </Box>
    )
}

export default TagListing;