import React, { Fragment, useState } from 'react';
import './event-listing.scss';
import {Box, TablePagination ,Table, TableBody, TableContainer, TableCell, TableHead, Paper, TableRow} from '@material-ui/core';
import {formatDate} from '../../helpers/dateFormatter';
import { useDispatch } from 'react-redux';
import {setOpenedEvent} from '../../store/openedEvent/actions';
import { openEventInfoModal } from '../../store/eventInfoModal/actions';
import {setEvents} from '../../store/events/actions';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import ConfirmationBox from '../ConfirmationBox/ConfirmationBox';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import services from '../../services';
import {showSnackbar} from '../../store/snackbar/actions';
import Modal from '../Modal/Modal';
import EventForm from '../Forms/EventForm/EventForm';

const EventListing = (props) => {
    const {events} = props
    const dateFormat = "d LLL, HH:mm"
    const [confirmDialog, setConfirmDialog] = useState(false)
    const dispatch = useDispatch()
    const [eventModal, setEventModal] = useState(false)
    const [selectedDeleteEvent, setSelectedDeleteEvent] = useState({})
    const [selectedEditEvent, setSelectedEditEvent] = useState({})

    const showInfo = (item) =>{
        dispatch(setOpenedEvent(item.id));
        dispatch(openEventInfoModal());
    }

    const deleteEvent = () => {
        services.events.remove(selectedDeleteEvent.id)
            .then((resp) => {
                dispatch(setEvents());
                setConfirmDialog(false)
            })
            .catch((error) => {
                console.log(error)
                dispatch(showSnackbar({message: "Something went wrong", type: "error"}))
            })
    }

    return (
        <Fragment>
            <TableContainer component={Paper} className="event-listing">
                <Table className="custom-table" size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell align="center">Title</TableCell>
                            <TableCell align="center">Event Start</TableCell>
                            <TableCell align="center">Event End</TableCell>
                            <TableCell align="center">Owner</TableCell>
                            <TableCell align="center">Categories</TableCell>
                            <TableCell align="center">Assisstants</TableCell>
                            <TableCell align="center">Guests</TableCell>
                            <TableCell align="center">Visits</TableCell>
                            <TableCell align="center"></TableCell>
                            <TableCell align="center">
                                <AddCircleOutlineIcon className="edit-icon" onClick={() => setEventModal(true)} />
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {events.data && events.data.map((row, index) => (
                            <TableRow key={index} onClick={() => showInfo(row)}>
                                <TableCell align="center" className="title">{row.title}</TableCell>
                                <TableCell align="center">{formatDate(row.start, dateFormat)}</TableCell>
                                <TableCell align="center">{formatDate(row.end, dateFormat)}</TableCell>
                                <TableCell align="center">{row.owner && row.owner.nickname}</TableCell>
                                <TableCell align="center">{row.categories && row.categories.map((cat, i) => <span key={i}> {cat.name}, </span>)}</TableCell>
                                <TableCell align="center">{row.assistants && row.assistants.length}</TableCell>
                                <TableCell align="center">{row.guests && row.guests.length}</TableCell>
                                <TableCell align="center">{row.visits ? row.visits : 0}</TableCell>
                                <TableCell align="center">
                                    <EditIcon className="edit-icon"
                                        onClick={(e) => {
                                            e.stopPropagation()
                                            setSelectedEditEvent(row)
                                            setEventModal(true)
                                        }} />
                                </TableCell>
                                <TableCell align="center">
                                    <DeleteIcon className="del-icon" onClick={(e) => {
                                        e.stopPropagation()
                                        setConfirmDialog(true)
                                        setSelectedDeleteEvent(row)
                                    }} />
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Box className="listing-pagination">
                <TablePagination
                    component="div"
                    count={events.total.length}
                    rowsPerPage={10}
                    rowsPerPageOptions={[10,15,20]}
                    backIconButtonText="Prev"
                    nextIconButtonText="Next"
                    page={events.page}
                    onChangePage={(e,page) => dispatch(setEvents(page))}/>
            </Box>
            <ConfirmationBox
                state={confirmDialog}
                persist={false}
                max={"sm"}
                dark={true}
                radius={false}
                onAgree={() => deleteEvent()}
                onDisagree={() => setConfirmDialog(false)}
                onClose={() => setConfirmDialog(false)}>

                <Fragment>Are you sure, you want to delete this event?</Fragment>
            </ConfirmationBox>
            <Modal
                state={eventModal}
                persist={false}
                max={"md"}
                dark={true}
                radius={false}
                onClose={() => {
                    setEventModal(false)
                    setSelectedEditEvent({})
                }}>

                <EventForm event={selectedEditEvent}
                    onClose={() => {
                        setEventModal(false)
                        setSelectedEditEvent({})
                    }}/>
            </Modal>
        </Fragment>
    )
}

export default EventListing;
