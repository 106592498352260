import React from 'react'
import CustomLink from '../CustomLink/CustomLink';
import {Box, Button} from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { logout } from '../../store/admin/actions';


const Menu = (props) => {
    const dispatch = useDispatch()
    const menu = [
        {name: 'dashboard', to: '/dashboard'},
        {name: 'manage explore', to: '/explore'},
        {name: 'users', to: '/users'},
        {name: 'events', to: '/events'},
        {name: 'tags', to: '/tags'},
        {name: 'promote discover', to: '/promote-discover'},
        {name: 'inbox contact', to: '/inbox'},
        {name: 'subscription plans', to: '/subscriptions'},
        {name: 'notifications', to: '/notifications'},
        {name: 'Twitch EventSub', to: '/twitch-eventsub'}
    ]

    return (
        <Box className="menu-items" display="flex" flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
            {menu.map((item, index) =>
                <CustomLink class={'link light-link'} key={index} to={item.to}>{item.name}</CustomLink>
            )}
            <Button className="link logout-btn" onClick={() => dispatch(logout())}>logout</Button>
        </Box>
    )
}

export default Menu;
