import React, {useState} from "react";
import './event-form.scss';
import {Grid, TextField, Button, Box, Switch} from "@material-ui/core";
import DateFnsUtils from '@date-io/date-fns';
import {MuiPickersUtilsProvider, KeyboardDatePicker, KeyboardTimePicker} from '@material-ui/pickers';
import services from "../../../services";
import {format} from 'date-fns';
import CategoriesList from './CategoriesList';
import ParticipantsList from './ParticipantsList';
import OwnerList from './OwnerList';
import {useSelector, useDispatch} from 'react-redux';
import {showSnackbar} from "../../../store/snackbar/actions";
import Modal from '../../Modal/Modal';
import CircleAdd from '../../../assets/icons/actions/add_event_circle.svg';
import ImageCropper from "../../ImageCropper/ImageCropper";
import { setEvents } from "../../../store/events/actions";

const EventForm = (props) => {
    const {event} = props
    const dispatch = useDispatch()
    const user = useSelector(state => state.user)
    const initDate = new Date()
    const initDate2 = new Date(new Date().setHours(new Date().getHours() + 1))
    const [title, setTitle] = useState((event && event.title) ? event.title : "")
    const [startDate, setStartDate] = useState((event && event.start) ? new Date(event.start) : initDate)
    const [endDate, setEndDate] = useState((event && event.end) ? new Date(event.end) : initDate2)
    const [startTime, setStartTime] = useState((event && event.start) ? new Date(event.start) : initDate)
    const [endTime, setEndtime] = useState((event && event.end) ? new Date(event.end) : initDate2)
    const [allDay, setAllDay] = useState((event && event.is_fullday) ? event.is_fullday : false)
    const [location, setLocation] = useState((event && event.location) ? event.location : "")
    const [website, setWebsite] = useState((event && event.website) ? event.website : "")
    const [description, setDescription] = useState((event && event.description) ? event.description : "")
    const [organizer, setOrganizer] = useState((event && event.organizer) ? event.organizer : "")
    const [categories, setCat] = useState(event.id ? event.categories : [])
    const [participants, setPart] = useState(event.id && event.guests.length > 0 ? event.guests : [])
    const [isPrivate, setIsPrivate] = useState((event && event.is_private) ? event.is_private : false);
    const [privateParticipants, setPrivatePart] = useState(event.id ? event.private_guest : [])
    const [titleError, setTitleError] = useState(false)
    const [startError, setStartError] = useState(false)
    const [endError, setEndError] = useState(false)
    const [poster, setPoster] = useState(event && event.poster ? event.poster : "/images/defaultHeader2.png")
    const [cropModal, showCropModal] = useState(false)
    const [croppedImage, setCroppedImage] = useState("")
    const [owner, setOwner] = useState("")

    console.log(event);

    const handleSubmit = (e) => {
        e.preventDefault()
        if(title){
            if(validateDates()){
                let start = startDate
                let end = endDate
                if(allDay) {
                    start.setHours(0);
                    start.setMinutes(0);
                    end.setHours(23);
                    end.setMinutes(59);
                } else {
                    start.setHours(new Date(startTime).getHours());
                    start.setMinutes(new Date(startTime).getMinutes());
                    end.setHours(new Date(endTime).getHours());
                    end.setMinutes(new Date(endTime).getMinutes());
                }

                let pp = []
                for(let a=0; a<participants.length; a++){
                    pp.push(participants[a].id)
                }
                let pp2 = []
                for(let a=0; a<privateParticipants.length; a++){
                    pp2.push(privateParticipants[a].id)
                }
                let data = {
                    title,
                    start: format(start, 'yyyy-MM-dd HH:mm:ssXXX'),
                    end: format(end, 'yyyy-MM-dd HH:mm:ssXXX'),
                    is_private: isPrivate,
                    location: location,
                    website: website,
                    description: description,
                    organizer: organizer,
                    is_fullday: allDay,
                    guests: pp,
                    categories: categories,
                    private_guest: pp2
                }
                if(owner){
                    data.ownerId = owner
                }
                saveEvent(data)
            } else {

            }
        } else {
            setTitleError(true)
            dispatch(showSnackbar({message: "Event title cannot be empty", type: "error"}))
        }
    }

    const validateDates = () => {
        if(endDate.getTime() < startDate.getTime()){
            dispatch(showSnackbar({message: "End Date should be greater than or equal to Start Date", type: "error"}))
            return false
        }
        if(startDate.getTime() === endDate.getTime()){
            if(endTime.getHours() < startTime.getHours()){
                dispatch(showSnackbar({message: "End Time should be greater than Start Time for same day Events", type: "error"}))
                return false
            }
        }
        return true
    }

    const handleStartTimeChange = (date) => {
        if(date && date.toString() !== 'Invalid Date'){
            setStartTime(date)
            setStartDate(new Date(new Date(startDate).setHours(new Date(date).getHours())))
                if(!event.hasOwnProperty('íd')) {
                    setEndDate(new Date(new Date(endDate).setHours(new Date(date).getHours()+1)))
                    setEndtime(new Date(new Date(date).setHours(new Date(date).getHours() + 1)))
                }
            setStartError(false)
            if(startDate > endDate) {
                setEndError(true)
            }
        }
    }

    const handleEndTimeChange = (date) => {
        if(date && date.toString() !== 'Invalid Date'){
            setEndtime(date)
            setEndDate(new Date(new Date(endDate).setHours(new Date(date).getHours())))
            setEndError(false)
        }
    }

    const handleOwnerChange = (data) => {
        if(data){
            setOwner(data.id)
        } else {
            setOwner(user.id)
        }
    }

    const handleStartDateChange = (date) => {
        setStartDate(date)
    }

    const handleEndDateChange = (date) => {
        setEndDate(date)
    }

    const handlePartChange = (data) => {
        setPart(data)
    }

    const handlePrivatePartChange = (data) => {
        setPrivatePart(data)
    }

    const handleCatChange = (data) => {
        setCat(data)
    }

    const handleKeyDown = (event) => {
        if(event.key === 'Enter'){
            handleSubmit(event)
        }
    }

    const saveEvent = (data) => {
        if(event.id){
            if(croppedImage){
                data.poster = croppedImage
            }
            services.events.patch(event.id, data, null)
                .then(() => {
                    dispatch(showSnackbar({message: "event edited successfully", type: "success"}))
                    props.onClose()
                    dispatch(setEvents())
                })
                .catch((error) => {
                    console.log(error)
                })
        } else{
            if(croppedImage){
                data.poster = croppedImage
            }
            services.events.create(data)
                .then((resp) => {
                    dispatch(showSnackbar({message: "event added successfully", type: "success"}))
                    props.onClose()
                    dispatch(setEvents())
                })
                .catch((error) => {
                    console.log(error)
                })
        }
    }

    const posterChange = (e) => {
        showCropModal(true)
        let file = e.target.files[0];
        var reader = new FileReader();
        reader.onloadend = function() {
            setPoster(reader.result)
        }
        reader.readAsDataURL(file);
    }

    return (
        <Grid container className="top-container">
            <Grid item xs={12} sm={7} md={7} lg={7} container direction="row" alignItems="flex-start" className="add-event-form">
                <Grid item xs={12} className="header">
                    <span className="box-title"> {event.id ? "edit event" : "add event" } </span>
                </Grid>
                <Grid item xs={12}>
                    <OwnerList initialData={event.id ? event.owner : user} owner={true} handleChange={handleOwnerChange} />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        required
                        className={"title input-dark"}
                        error={titleError}
                        type="text"
                        placeholder="Title"
                        value={title}
                        variant="outlined"
                        onKeyDown={handleKeyDown}
                        onChange={(e) => {
                            setTitle(e.target.value)
                            !e.target.value ? setTitleError(true) : setTitleError(false)
                        }}
                    />
                </Grid>
                <Grid item xs={12} className="tabs">
                    <Box bgcolor="grid.main" className="section">
                        <Grid container spacing={2}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <Grid item xs={6}>
                                    <KeyboardTimePicker
                                        required
                                        className={"time input-dark"}
                                        error={startError}
                                        format="HH:mm"
                                        variant="dialog"
                                        placeholder="Start Time"
                                        value={startTime}
                                        label="Start Time"
                                        onKeyDown={handleKeyDown}
                                        onChange={handleStartTimeChange}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <KeyboardDatePicker
                                        required
                                        className="time input-dark"
                                        variant="dialog"
                                        error={startError}
                                        format="d LLLL yyyy"
                                        placeholder="Start Date"
                                        value={startDate}
                                        onKeyDown={handleKeyDown}
                                        onChange={handleStartDateChange}
                                    />
                                </Grid>
                            </MuiPickersUtilsProvider>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <Grid item xs={6}>
                                    <KeyboardTimePicker
                                        required
                                        className={"time input-dark"}
                                        placeholder="End Time"
                                        error={endError}
                                        format="HH:mm"
                                        variant="dialog"
                                        value={endTime}
                                        label="End Time"
                                        onKeyDown={handleKeyDown}
                                        onChange={handleEndTimeChange}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <KeyboardDatePicker
                                        required
                                        className="time input-dark"
                                        variant="dialog"
                                        format="d LLLL yyyy"
                                        placeholder="End Date"
                                        error={endError}
                                        value={endDate}
                                        onKeyDown={handleKeyDown}
                                        onChange={handleEndDateChange}
                                    />
                                </Grid>
                            </MuiPickersUtilsProvider>
                            <Grid item xs={6}>
                                <TextField
                                    fullWidth
                                    className="input-dark"
                                    placeholder="Location"
                                    value={location}
                                    variant="outlined"
                                    onKeyDown={handleKeyDown}
                                    onChange={(e) => setLocation(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    fullWidth
                                    className="input-dark"
                                    placeholder="website"
                                    value={website}
                                    variant="outlined"
                                    onKeyDown={handleKeyDown}
                                    onChange={(e) => setWebsite(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    className="input-dark"
                                    placeholder="Organizer"
                                    value={organizer}
                                    variant="outlined"
                                    onKeyDown={handleKeyDown}
                                    onChange={(e) => setOrganizer(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <CategoriesList quick={false} initialData={categories} handleChange={handleCatChange} />
                            </Grid>
                            <Grid item xs={12}>
                                <ParticipantsList quick={false} initialData={participants} handleChange={handlePartChange} />
                            </Grid>
                            <Grid item container direction="row" justify="flex-start" xs={12} sm={6} md={6} lg={6}>
                                <Box display="flex" flexDirection="row" justifyContent="flex-start" alignItems="center">
                                    <Switch checked={isPrivate} onChange={(e) => setIsPrivate(e.target.checked)} className="switch" />
                                    <span>{isPrivate ? "Event Private" : "Event Public"}</span>
                                </Box>
                            </Grid>
                            <Grid item container direction="row" alignItems="center" xs={12} sm={6} md={6} lg={6}>
                                <Box display="flex" flexDirection="row" justifyContent="flex-start" alignItems="center">
                                    <Switch checked={allDay} onChange={(e) => setAllDay(e.target.checked)} className="switch" />
                                    <span>{allDay ? "All Day" : "Selected Hours"}</span>
                                </Box>
                            </Grid>
                            {isPrivate &&
                                <Grid item xs={12}>
                                    <ParticipantsList quick={false} private={true} initialData={privateParticipants} handleChange={handlePrivatePartChange} />
                                </Grid>
                            }
                        </Grid>
                    </Box>
                </Grid>
            </Grid>
            <Grid className="right-side-box" item container xs={12} sm={5} md={5} lg={5} direction="row" justify="center">
                <Grid item xs={12} container direction="row" justify="flex-end">
                    <Box bgcolor="grid.main" className="poster-box" display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                        {(croppedImage) && <img className="poster-display" src={croppedImage ? croppedImage : poster} alt=""/>}

                        <Box className={croppedImage ? "absolute" : "upload-icon"} display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                            <label id="add-event-image-label" htmlFor="add-event-image"><img src={CircleAdd} alt="latency" /></label>
                            <input type="file" id="add-event-image" accept="image/gif, image/jpeg, image/png" onChange={posterChange} />
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={12} container direction="row" justify="flex-end">
                    <Box className="description-box">
                        <TextField
                            multiline
                            rows={7}
                            className={"input-textarea grey input-dark"}
                            type="text"
                            placeholder="Description"
                            value={description}
                            onKeyDown={handleKeyDown}
                            variant="outlined"
                            onChange={(e) => {
                                setDescription(e.target.value)
                            }}
                        />
                    </Box>
                </Grid>
            </Grid>
            <Grid item xs={12} container direction="row" alignItems="center" justify="center">
                <Box mt={2}>
                    <Button className="cancel-btn wide mr" onClick={props.onClose}> Cancel </Button>
                    <Button className="submit-btn wide" onClick={(e) => handleSubmit(e)}> Save Event </Button>
                </Box>
            </Grid>
            <Modal
                state={cropModal}
                persist={false}
                max={"lg"}
                fullScreen={false}
                onClose={() => showCropModal(false)} >

                <ImageCropper
                    cropper={{width: 480, height: 680}}
                    poster={poster}
                    className="add-event"
                    onCrop={(img) => setCroppedImage(img)}
                    onClose={() => showCropModal(false)} />
            </Modal>
        </Grid>
    );
}

export default EventForm;
