import services from '../../services';
import {store} from '../';

export const setUsers = (page) => {
    return dispatch => {
        const state = store.getState()
        const limit = 10
        const skip = page >= 0 ? (page * limit) : (state.users.page * limit)
        const query = {query: {$limit: limit, $skip: skip, $sort: {createdAt: -1}}, admin: true}
        services.users.find(query)
            .then((resp) => {
                resp.page = page >= 0 ? page : state.users.page
                dispatch({
                    type: 'SET_USERS',
                    payload: resp
                })
            })
            .catch((error) => {
                console.log(error)
            })
    }
}

export const unsetUsers = () => {
    return dispatch => {
        dispatch({type: 'UNSET_USERS'})
    }
}
