import client from './client';

const services = {
    users: client.service('/users'),
    events: client.service('/events'),
    authManagement: client.service('/authManagement'),
    interests: client.service('/interests'),
    categories: client.service('/categories'),
    groups: client.service('/groups'),
    search: client.service('/search'),
    recommendations: client.service('/user-recommendation'),
    follow: client.service('/user-follow'),
    messages: client.service('/event-chat'),
    notifications: client.service('/notifications'),
    discover: client.service('/discover-user'),
    dashboard: client.service('/admin-dashboard'),
    explore: client.service('/explore'),
    exploreEvent: client.service('/explore-event'),
    inbox: client.service('/contact-us'),
    userSubscription: client.service('/subscription'),
    subscriptionPlans: client.service('/subscription-plans'),
    subscriptionPlanAction: client.service('/subscription-plan-action'),
    subscriptionPlanVoucher: client.service('/subscription-plan-voucher'),
    twitchEventSub: client.service('/twitch-eventsub')
};

export default services;
