import services from '../../services';

export const setSubscriptions = () => {
    return dispatch => {
        services.subscriptionPlans.find()
            .then((resp) => {
                dispatch({
                    type: 'SET_SUBSCRIPTIONS',
                    payload: resp
                })
            })
    }
}

export const unsetSubscriptions = () => {
    return dispatch => {
        dispatch({type: 'UNSET_SUBSCRIPTIONS'})
    }
}
