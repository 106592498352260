import React from 'react';
import './confirmation-box.scss';
import { Dialog, Box, Button } from '@material-ui/core';

const ConfirmationBox = (props) => {

    return (
        <Dialog 
            open={props.state}
            onClose={props.onClose}
            classes={{paper: 'modal ' + (props.radius ? ' radius ' : ' ') + (' dark')}}
            maxWidth={props.max}
            disableBackdropClick={props.persist}>
                
            <p> {props.children} </p>
            <Box display="flex" flexDirection="row" justifyContent="flex-end">
                <Button onClick={props.onDisagree}>no</Button>
                <Button onClick={props.onAgree}>yes</Button>
            </Box>
        </Dialog>
    )
}

export default ConfirmationBox;