import services from '../../services/';

export const setInterests = (data) => {
    return dispatch => {
        services.interests.find({query: {$limit: 100}})
            .then((resp) => {
                dispatch({
                    type: 'SET_INTERESTS',
                    payload: resp.data
                })
            })
    }
}

export const unsetInterests = () => {
    return dispatch => {
        dispatch({type: 'UNSET_INTERESTS'})
    }
}
