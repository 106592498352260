import React, { useState } from 'react'
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import services from '../../../../services';

const ParticipantsList = (props) => {
    const [values, setValues] = useState([])
    const [list, setList] = useState([])
    const [loading, setLoading] = useState(false)

    const handleChange = (event, data) => {
        setValues(data)
        props.handleChange(data)
    }

    const handleSearch = (event) => {
        if(event.target.value && event.target.value.length >= 2){
            setLoading(true)
            services.search.find({
                query: {
                    text: event.target.value,
                    participants: true
                }
            })
            .then((resp) => {
                setList(resp.users)
            })
            .catch((error) => {
                console.log(error)
            })
        }
    }

    return (
        <Autocomplete
            multiple
            renderInput={params => (
                <TextField
                    {...params}
                    variant="outlined"
                    onChange={handleSearch}
                    placeholder={"Select User"}
                    fullWidth
                />
            )}
            className={"tag-select"}
            loading={loading}
            classes={{tag: "chip", popper: "full-tag-select-dropdown", endAdornment: "clear-icon", popupIndicator: "down-btn", loading: "static", noOptions: "static"}}
            options={list}
            getOptionLabel={option => option.nickname ? option.nickname : ""}
            value={values}
            onChange={handleChange}
        />
    )
}


export default ParticipantsList;