import React from 'react';
import './user-info.scss';
import { Box, Button } from '@material-ui/core';
import { formatDate } from '../../helpers/dateFormatter';
import services from '../../services';
import { useSelector, useDispatch } from 'react-redux';
import { setOpenedProfile } from '../../store/openedProfile/actions';
import { setUsers } from '../../store/users/actions';

const UserInfo = (props) => {
    const openedProfile = useSelector(state => state.openedProfile)
    const dispatch = useDispatch()

    const verifyUser = () => {
        services.users.patch(openedProfile.id, {account_verified: true}, null)
            .then((resp) => {
                dispatch(setOpenedProfile(openedProfile.id))
                dispatch(setUsers())
            })
            .catch((error) => {
                console.log(error)
            })
    }

    const unverifyUser = () => {
        services.users.patch(openedProfile.id, {account_verified: false}, null)
            .then((resp) => {
                dispatch(setOpenedProfile(openedProfile.id))
                dispatch(setUsers())
            })
            .catch((error) => {
                console.log(error)
            })
    }

    return (
        <Box className="user-info" display="flex" flexDirection="column" justifyContent="center" alignItems="center">
            <Box className={"avatar-boxs"}>
                <img width={128} height={128} src={openedProfile && openedProfile.avhatar ? process.env.REACT_APP_FRONTEND_URL + openedProfile.avatar : "/images/defaultAvatar.png"} alt="latency" className="avatar" />
            </Box>
            <Box className="info-box">
                <h4 className="nickname"> {openedProfile && openedProfile.nickname} </h4>
                <h4 className="name"> {openedProfile && openedProfile.firstName && openedProfile.lastName && openedProfile.firstName + " " + openedProfile.lastName} </h4>
                {openedProfile && openedProfile.birthday && <span className="subtitle">birthday: <span>{formatDate(openedProfile.birthday, "yyyy/mm/dd")}</span> </span>}
                {openedProfile && openedProfile.website && <span className="subtitle">website: <span>{openedProfile.website}</span> </span>}
                {openedProfile && openedProfile.location && <span className="subtitle">location: <span>{openedProfile.location}</span> </span>}
                {openedProfile && openedProfile.biography && <span className="subtitle bio">biography: <span>{openedProfile.biography}</span> </span>}
                {!openedProfile.account_verified && <Button className="submit-btn" onClick={verifyUser}>verify user</Button>}
                {openedProfile.account_verified && <Button className="submit-btn" onClick={unverifyUser}>Unverify user</Button>}
            </Box>
        </Box>
    )
}

export default UserInfo;
