const initailState = false

const loaderReducer = (state = initailState, action) => {
    switch(action.type){
        case 'SHOW_LOADER':
            return state = true;
        case 'HIDE_LOADER':
            return state = initailState
        default:
            return state
    }
}

export default loaderReducer;