import React, { useState } from 'react';
import './explore-topbar.scss';
import {Box} from '@material-ui/core';
import { useSelector } from 'react-redux';

const InterestsForm = (props) => {
    const [selected, setSelected] = useState({})
    const explore = useSelector(state => state.explore)

    const onChange = (item) => {
        setSelected(item)
        props.onChange(item)
    }

    return (
        <Box className="interests" display="flex" flexDirection="row" justifyContent="center" flexWrap="wrap">
            {explore && explore.map(item =>
                <Box onClick={() => onChange(item)}
                    key={item.id}
                    display="flex"
                    flexDirection="row"
                    justifyContent="center"
                    alignItems="center"
                    className={"parent-box " + (item.id === selected.id ? "checked" : "")}>

                    {item.interest && item.interest.text}
                </Box>
            )}
        </Box>
    )
}

export default InterestsForm;
