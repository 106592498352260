import React, { useState } from 'react';
import './explore-user-form.scss';
import { Box, Button } from '@material-ui/core';
import UserList from './UserList';
import services from '../../../../services';
import { useDispatch } from 'react-redux';
import { setExplore } from '../../../../store/explore/actions';

const TagForm = (props) => {
    const {onClose, data} = props
    const [selectedUser, setSelectedUser] = useState([])
    const dispatch = useDispatch()

    const submit = () => {
        if(selectedUser.length > 0 && data.id){
            let uu = []
            for(let a=0; a<selectedUser.length; a++){
                uu.push(selectedUser[a].id)
            }
            services.explore.patch(data.id, {users: uu})
                .then((resp) => {
                    dispatch(setExplore())
                    props.onClose()
                })
                .catch((error) => {
                    console.log(error)
                })
        }
    }

    const handleUserChange = (val) => {
        setSelectedUser(val)
    }

    return (
        <Box className="explore-user-form">
            <Box mb={5} minWidth="100%" maxWidth="100%">
                <span className="box-title">add users</span>
            </Box>
            <Box mb={2} minWidth="100%" maxWidth="100%">
                <UserList handleChange={handleUserChange} />
            </Box>
            <Box mt={5} minWidth="100%" maxWidth="100%" display="flex" flexDirection="row" justifyContent="center">
                <Button className="cancel-btn" onClick={onClose}>Cancel</Button>
                <Button className="submit-btn" onClick={submit}>Save</Button>
            </Box>
        </Box>
    )
}

export default TagForm;